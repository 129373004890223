import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import DocumentTypeIcon from '@/components/document-type-icon.vue';
import ViewerLink from '@/components/viewer-link.vue';
import { useTelemetry } from '@/plugins/telemetry';
import { formatYmd } from '@/utility';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
export default defineComponent({
  components: {
    ViewerLink: ViewerLink,
    DocumentTypeIcon: DocumentTypeIcon
  },
  props: {
    selectedReference: {
      type: Object,
      default: null
    },
    handleSelectReference: {
      type: Function,
      required: true
    },
    law: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    answer: {
      type: [Array, String],
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var expose = _ref.expose;
    var telemetry = useTelemetry();
    var route = useRoute();
    var showReadMore = ref(false);
    var handleShowReadMore = function handleShowReadMore(index) {
      showReadMore.value = true;
    };
    var handleClickSection = function handleClickSection() {
      props.handleSelectReference({
        type: DocumentTypeEnum.Law,
        document: props.index,
        section: null,
        focus: false,
        scroll: false
      });
      telemetry.sendClickTelemetry({
        button: 'watson-reference-item__law',
        params: {
          targetId: props.law.docId,
          key: props.law.key,
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var isCitedInHolmes = computed(function () {
      if (!Array.isArray(props.answer)) {
        return false;
      }
      return props.answer.some(function (answer) {
        return answer.ref && answer.ref.some(function (r) {
          return r.document === props.index;
        });
      });
    });
    expose({
      handleShowReadMore: handleShowReadMore
    });
    return {
      showReadMore: showReadMore,
      handleClickSection: handleClickSection,
      handleShowReadMore: handleShowReadMore,
      formatYmd: formatYmd
    };
  }
});