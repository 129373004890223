var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{ref:"intersection-observer",staticClass:"reference pl-5 pr-1 pt-1",class:{
    inactive: _vm.selectedReference && _vm.selectedReference.focus && _vm.selectedReference.document !== _vm.index,
  }},[_c('document-list-item',{attrs:{"variant":"small","disabled":"","document":_vm.reference.record,"target":"_blank"}}),_vm._v(" "),_c('ol',{staticClass:"reference-list"},_vm._l((_vm.reference.references),function({ key, sectionLabels, content },i){return _c('li',{key:key + '-' + i,ref:`reference-${_vm.index}-${i}`,refInFor:true,staticClass:"d-flex flex-column reference-item",class:{
        inactive:
          _vm.selectedReference &&
          _vm.selectedReference.focus &&
          _vm.selectedReference.document === _vm.index &&
          _vm.selectedReference.section !== i,
        active: _vm.showReadMores[i],
        select: _vm.selectedReference && _vm.selectedReference.document === _vm.index && _vm.selectedReference.section === i,
      }},[_c('viewer-link',{staticClass:"section-labels",attrs:{"href-document":_vm.reference.record.id + '#key=' + key,"target":"_blank"},on:{"click":function($event){return _vm.handleLinkClick(key, i)}}},[_vm._v("\n        "+_vm._s(sectionLabels.join(' » '))+"\n      ")]),_vm._v(" "),_c('blockquote',{on:{"click":function($event){return _vm.handleSectionClick(key, i)}}},[_c('p',{staticClass:"snippet"},[_vm._v(_vm._s(content.replace(/<[^>]+>/g, '')))])]),_vm._v(" "),(!_vm.showReadMores[i])?_c('v-btn',{staticClass:"snippet--read-more",attrs:{"text":""},on:{"click":function($event){return _vm.handleSectionClick(key, i)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-down")]),_vm._v("続きを見る\n      ")],1):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }