var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{ref:"card",class:[
    'case-item',
    'no-user-select',
    {
      'inactive-case-item': _vm.selectedReference && _vm.selectedReference.focus && _vm.selectedReference.document !== _vm.index,
      'active-case-item': _vm.selectedReference && _vm.selectedReference.document === _vm.index,
    },
  ],attrs:{"href":_vm.caseReference.record.url,"target":"_blank"},on:{"mouseenter":function($event){return _vm.handleHover()},"click":function($event){return _vm.handleClick()}}},[_c('v-card-title',[_c('h2',{staticClass:"d-flex align-baseline"},[_c('document-type-icon',{attrs:{"type":"case"}}),_vm._v(" "),_c('a',{staticClass:"text-none text-decoration-none ml-2",attrs:{"href":_vm.caseReference.record.url,"target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.caseReference.record.displayName)),(_vm.caseReference.record.caseName)?_c('small',[_vm._v("（"+_vm._s(_vm.caseReference.record.caseName)+"）")]):_vm._e()])],1)]),_vm._v(" "),_c('v-card-text',{ref:`reference-${_vm.index}-null`},[_c('div',{staticClass:"case-item-meta-info"},[_c('div',{attrs:{"title":"出典"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-newspaper-variant-outline")]),_vm._v(_vm._s(_vm.caseReference.record.source))],1),_vm._v(" "),(_vm.caseReference.record.caseNumber)?_c('div',{attrs:{"title":"事件番号"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder-pound-outline")]),_vm._v(_vm._s(_vm.caseReference.record.caseNumber)+"\n      ")],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"reference-list"},[_vm._l((_vm.subitems.youshiShown),function({ txt, kind, score, label, subIndex }){return _c('blockquote',{key:subIndex,staticClass:"reference-item",on:{"mouseenter":function($event){return _vm.handleSectionHover(kind, subIndex)},"click":function($event){return _vm.handleSectionClick(kind, subIndex)}}},[_c('p',{staticClass:"section-labels",class:{ 'positive-score': score },domProps:{"textContent":_vm._s(label)}}),_vm._v(" "),_c('p',{class:{
            'snippet--short': !(_vm.isExpanded && _vm.d1Option),
            'snippet--full': _vm.isExpanded && _vm.d1Option,
            'black--text': score,
          },domProps:{"textContent":_vm._s(txt)}})])}),_vm._v(" "),_c('v-slide-y-transition',[(_vm.isExpanded)?_c('div',_vm._l((_vm.subitems.hidden),function({ txt, kind, score, label, subIndex }){return _c('blockquote',{key:subIndex,staticClass:"reference-item",on:{"mouseenter":function($event){return _vm.handleSectionHover(kind, subIndex)},"click":function($event){return _vm.handleSectionClick(kind, subIndex)}}},[_c('p',{staticClass:"section-labels",class:{ 'positive-score': score },domProps:{"textContent":_vm._s(label)}}),_vm._v(" "),_c('p',{class:{ 'snippet--short': !_vm.d1Option, 'snippet--full': _vm.d1Option, 'black--text': score },domProps:{"textContent":_vm._s(txt)}})])}),0):_vm._e()]),_vm._v(" "),(!_vm.isExpanded && (_vm.d1Option || _vm.subitems.hidden.length > 0))?_c('v-btn',{staticClass:"snippet--read-more",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isExpanded = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-down")]),_vm._v("続きを見る\n      ")],1):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }