var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{ref:"card",staticClass:"reference guideline-item",class:{
    inactive: _vm.selectedReference && _vm.selectedReference.focus && _vm.selectedReference.document !== _vm.index,
  }},[_c('viewer-link',{staticClass:"text-decoration-none",attrs:{"href-document":_vm.reference.record.naturalId,"target":"_blank"}},[_c('v-card-title',{staticClass:"card-title"},[_c('h2',{staticClass:"d-flex align-baseline"},[_c('document-type-icon',{attrs:{"type":_vm.reference.record.type}}),_vm._v(" "),_c('div',{staticClass:"guideline-item__title text-none text-decoration-none ml-2"},[_vm._v("\n          "+_vm._s(_vm.reference.record.title)+"\n        ")])],1)])],1),_vm._v(" "),_c('v-card-text',{staticClass:"pa-0 guideline-item__body"},[_c('div',{staticClass:"guideline-item__meta-info"},[_c('div',{attrs:{"title":"所管省庁"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-office-building")]),_vm._v(_vm._s(_vm.reference.record.publisher))],1),_vm._v(" "),(_vm.reference.record.publishedOn >= 0)?_c('div',{staticClass:"published-on"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-edit")]),_vm._v(" "+_vm._s(_vm.formatYmd(new Date(_vm.reference.record.publishedOn * 1000)))+"\n        施行\n      ")],1):_vm._e()]),_vm._v(" "),_vm._l((_vm.sections),function(section,sectionIndex){return _c('div',{key:sectionIndex},[_c('div',{staticClass:"reference-section"},[_c('div',{staticClass:"section-labels"},[(section[0].headings[0])?_c('div',{staticClass:"section-labels__heading"},[_vm._v("\n            "+_vm._s(section[0].headings[0])+"\n          ")]):_vm._e(),_vm._v(" "),_c('viewer-link',{staticClass:"section-labels__page-number",attrs:{"href-document":_vm.reference.record.naturalId + '?view=pdf#page=' + section[0].pdfPageNumbers[0],"target":"_blank"}},[_vm._v("\n            "+_vm._s(section[0].pageNumbersStr)+"\n          ")])],1),_vm._v(" "),_vm._l((section),function({ content, subIndex }){return _c('div',{key:subIndex,ref:`reference-${_vm.index}-${subIndex}`,refInFor:true,staticClass:"reference-item",class:{
            inactive:
              _vm.selectedReference &&
              _vm.selectedReference.focus &&
              _vm.selectedReference.document === _vm.index &&
              _vm.selectedReference.section !== subIndex,
            active: _vm.showReadMores[subIndex],
            select:
              _vm.selectedReference && _vm.selectedReference.document === _vm.index && _vm.selectedReference.section === subIndex,
          }},[_c('blockquote',{staticClass:"reference-body",on:{"click":function($event){return _vm.handleClickSection(subIndex)}}},[_c('p',{staticClass:"snippet",domProps:{"textContent":_vm._s(content)}})]),_vm._v(" "),(!_vm.showReadMores[subIndex])?_c('v-btn',{staticClass:"snippet--read-more",attrs:{"text":""},on:{"click":function($event){return _vm.handleClickSection(subIndex)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-down")]),_vm._v("続きを見る\n          ")],1):_vm._e()],1)})],2)])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }