import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import DocumentListItem from '@/components/document-list-item.vue';
import ViewerLink from '@/components/viewer-link.vue';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { useTelemetry } from '@/plugins/telemetry';
import { ImpressionDetector } from '@/utils/impressionUtils';
export default defineComponent({
  components: {
    DocumentListItem: DocumentListItem,
    ViewerLink: ViewerLink
  },
  props: {
    selectedReference: {
      type: Object,
      default: null
    },
    handleSelectReference: {
      type: Function,
      required: true
    },
    reference: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    answer: {
      type: [Array, String],
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var expose = _ref.expose;
    var telemetry = useTelemetry();
    var route = useRoute();
    var intersectionObserver = ref();
    var impressionDetector = ref();
    var showReadMores = ref(Array(props.reference.references.length).fill(false));
    onMounted(function () {
      if (!intersectionObserver.value) {
        return;
      }
      impressionDetector.value = new ImpressionDetector(telemetry, route, intersectionObserver.value,
      // 30% 以上の領域が 5000 ミリ秒以上表示されたらインプレッションイベントを送る
      0.3, 5000, 'watson-reference-item', props.reference.record.id);
    });
    onBeforeUnmount(function () {
      var _impressionDetector$v;
      (_impressionDetector$v = impressionDetector.value) === null || _impressionDetector$v === void 0 ? void 0 : _impressionDetector$v.cleanup();
    });
    var handleShowReadMore = function handleShowReadMore(index) {
      showReadMores.value[index] = true;
    };
    var handleSectionClick = function handleSectionClick(key, sectionIndex) {
      props.handleSelectReference({
        type: DocumentTypeEnum.Book,
        document: props.index,
        section: sectionIndex,
        focus: false,
        scroll: false
      });
      telemetry.sendClickTelemetry({
        button: 'watson-reference-item__section',
        params: {
          targetId: props.reference.record.id,
          key: key,
          // /document/{naturalId}#key={key}
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          sectionIndex: sectionIndex,
          // 文献の中で何番目に表示されているセクションか
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var handleLinkClick = function handleLinkClick(key, sectionIndex) {
      telemetry.sendClickTelemetry({
        button: 'watson-reference-item__section',
        params: {
          targetId: props.reference.record.id,
          key: key,
          // /document/{naturalId}#key={key}
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          sectionIndex: sectionIndex,
          // 文献の中で何番目に表示されているセクションか
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var isCitedInHolmes = computed(function () {
      if (!Array.isArray(props.answer)) {
        return false;
      }
      return props.answer.some(function (answer) {
        return answer.ref && answer.ref.some(function (r) {
          return r.document === props.index;
        });
      });
    });
    expose({
      handleShowReadMore: handleShowReadMore
    });
    return {
      showReadMores: showReadMores,
      handleShowReadMore: handleShowReadMore,
      handleSectionClick: handleSectionClick,
      handleLinkClick: handleLinkClick
    };
  }
});