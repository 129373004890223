import DocumentTypeIcon from '@/components/document-type-icon.vue';
import HolmesReferenceMarker from '@/components/labs/wandh/holmes-reference-marker.vue';
import HolmesIcon from '@/components/labs/holmes-icon.vue';
import Nekomimi from '@/components/labs/nekomimi.vue';
import EvaluationHolmesGoodBad from '@/components/labs/wandh/evaluation-holmes-good-bad.vue';
import { parseWandhModelVersion } from './model';
import { useStore } from '@/store/useStore';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
export default defineComponent({
  components: {
    EvaluationHolmesGoodBad: EvaluationHolmesGoodBad,
    HolmesReferenceMarker: HolmesReferenceMarker,
    HolmesIcon: HolmesIcon,
    Nekomimi: Nekomimi,
    DocumentTypeIcon: DocumentTypeIcon
  },
  props: {
    sentQuery: {
      type: Object,
      required: true
    },
    state: {
      type: String,
      required: true
    },
    answer: {
      type: [Array, String],
      required: true
    },
    answerTimered: {
      type: Array,
      required: true
    },
    error: {
      type: Boolean,
      required: true
    },
    selectedReference: {
      type: Object,
      default: null
    },
    handleSelectReference: {
      type: Function,
      required: true
    },
    references: {
      type: [Array, Object],
      required: true
    },
    laws: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    var store = useStore();
    var sentQueryModel = computed(function () {
      return props.sentQuery && parseWandhModelVersion(props.sentQuery.model);
    });
    var handleClickOnLaw = function handleClickOnLaw(key) {
      props.handleSelectReference({
        type: DocumentTypeEnum.Law,
        document: key,
        section: null,
        focus: true,
        scroll: true
      });
    };
    var showDetailedReference = ref(store.state.persistent.showDetailedReference);
    watch(showDetailedReference, function (newVal) {
      store.commit('setShowDetailedReference', newVal);
    });
    return {
      sentQueryModel: sentQueryModel,
      showDetailedReference: showDetailedReference,
      handleClickOnLaw: handleClickOnLaw
    };
  }
});