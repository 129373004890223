import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.includes.js";
import { useToast } from '@/plugins/vueToast';
import WatsonIcon from '@/components/labs/watson-icon.vue';
import Nekomimi from '@/components/labs/nekomimi.vue';
import EvaluationWatsonStars from '@/components/labs/wandh/evaluation-watson-stars.vue';
import EvaluationWatsonDetails from '@/components/labs/wandh/evaluation-watson-details.vue';
import WatsonReferenceItem from '@/components/labs/wandh/watson-reference-item.vue';
import WatsonReferenceCaseItem from '@/components/labs/wandh/watson-reference-case-item.vue';
import WatsonReferenceGuidelineItem from '@/components/labs/wandh/watson-reference-guideline-item.vue';
import WatsonLawItem from '@/components/labs/wandh/watson-law-item.vue';
import WatsonTab from '@/components/labs/wandh/watson-tab.vue';
import { useTelemetry } from '@/plugins/telemetry';
import { parseWandhModelVersion } from './model';
import { useWandhGuideline } from '@/plugins/wandhGuideline';
export default defineComponent({
  components: {
    EvaluationWatsonDetails: EvaluationWatsonDetails,
    EvaluationWatsonStars: EvaluationWatsonStars,
    WatsonIcon: WatsonIcon,
    Nekomimi: Nekomimi,
    WatsonReferenceItem: WatsonReferenceItem,
    WatsonReferenceCaseItem: WatsonReferenceCaseItem,
    WatsonReferenceGuidelineItem: WatsonReferenceGuidelineItem,
    WatsonLawItem: WatsonLawItem,
    WatsonTab: WatsonTab
  },
  props: {
    sentQuery: {
      type: Object,
      default: null
    },
    state: {
      type: String,
      required: true
    },
    selectedReference: {
      type: Object,
      default: null
    },
    handleSelectReference: {
      type: Function,
      required: true
    },
    handleUnfocusReference: {
      type: Function,
      required: true
    },
    references: {
      type: [Array, Object],
      required: true
    },
    answer: {
      type: [Array, String],
      required: true
    },
    watsonTab: {
      type: String,
      required: true
    },
    watsonTabs: {
      type: Array,
      required: true
    },
    changeWatsonTab: {
      type: Function,
      required: true
    },
    laws: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    var toast = useToast();
    var telemetry = useTelemetry();
    var route = useRoute();
    var wandhGuideline = useWandhGuideline();
    var showEvaluationWatsonDetails = ref(false);
    var handleShowDetails = function handleShowDetails() {
      showEvaluationWatsonDetails.value = true;
    };
    var sentQueryModel = computed(function () {
      return props.sentQuery && parseWandhModelVersion(props.sentQuery.model);
    });
    var publishedOnOrAfterYear = computed(function () {
      var _props$sentQuery, _props$sentQuery$filt;
      return (_props$sentQuery = props.sentQuery) !== null && _props$sentQuery !== void 0 && (_props$sentQuery$filt = _props$sentQuery.filter) !== null && _props$sentQuery$filt !== void 0 && _props$sentQuery$filt.publishedOnOrAfterYear ? "\u767A\u884C\u5E74\u6708\u65E5 ".concat(props.sentQuery.filter.publishedOnOrAfterYear, "\u5E74\u301C") : '全期間';
    });
    var decidedOnOrAfterYear = computed(function () {
      var _props$sentQuery2, _props$sentQuery2$fil;
      return (_props$sentQuery2 = props.sentQuery) !== null && _props$sentQuery2 !== void 0 && (_props$sentQuery2$fil = _props$sentQuery2.filter) !== null && _props$sentQuery2$fil !== void 0 && _props$sentQuery2$fil.publishedOnOrAfterYear ? "\u88C1\u5224\u5E74\u6708\u65E5 ".concat(props.sentQuery.filter.publishedOnOrAfterYear, "\u5E74\u301C") : '全期間';
    });
    var genre = computed(function () {
      var _props$sentQuery3, _props$sentQuery3$fil, _props$sentQuery3$fil2;
      return (_props$sentQuery3 = props.sentQuery) !== null && _props$sentQuery3 !== void 0 && (_props$sentQuery3$fil = _props$sentQuery3.filter) !== null && _props$sentQuery3$fil !== void 0 && (_props$sentQuery3$fil2 = _props$sentQuery3$fil.genre) !== null && _props$sentQuery3$fil2 !== void 0 && _props$sentQuery3$fil2.length ? props.sentQuery.filter.genre.join(',') : '全書籍';
    });

    // FIXME booksReferences, caseReferences は本当は型ガードを定義した方がよい
    var bookReferences = computed(function () {
      return props.references.map(function (ref, index) {
        return _objectSpread(_objectSpread({}, ref), {}, {
          index: index
        });
      }).filter(function (reference) {
        return reference.record.type === 'book';
      });
    });
    var caseReferences = computed(function () {
      return props.references.map(function (ref, index) {
        return _objectSpread(_objectSpread({}, ref), {}, {
          index: index
        });
      }).filter(function (reference) {
        return reference.record.type === 'case';
      });
    });
    var guidelineReferences = computed(function () {
      return props.references.map(function (ref, index) {
        return _objectSpread(_objectSpread({}, ref), {}, {
          index: index
        });
      }).filter(function (reference) {
        return reference.record.type === 'guideline' || reference.record.type === 'guideline_qa';
      });
    });
    var tsutatsuReferences = computed(function () {
      return props.references.map(function (ref, index) {
        return _objectSpread(_objectSpread({}, ref), {}, {
          index: index
        });
      }).filter(function (reference) {
        return reference.record.type === 'tsutatsu' || reference.record.type === 'tsutatsu_qa';
      });
    });
    var tabKey = ref(0);
    watch(function () {
      return props.watsonTab;
    }, function () {
      tabKey.value = props.watsonTabs.filter(isIncluded).indexOf(props.watsonTab);
    });
    function tabName(tab) {
      if (tab === 'book') {
        return '書籍';
      } else if (tab === 'case') {
        return '判例';
      } else if (tab === 'guideline') {
        return 'ガイドライン等';
      } else if (tab === 'law') {
        return '法令';
      }
      return '';
    }
    function getTabIcon(tab) {
      var iconName = isIncluded(tab) ? tab : "".concat(tab, "-disabled");
      return require("@/assets/wandh-".concat(iconName, ".svg"));
    }
    var tabClickHandler = function tabClickHandler(tab) {
      props.changeWatsonTab(tab);
      telemetry.sendClickTelemetry({
        button: 'watson__tab',
        params: {
          tabType: tab
        }
      }, route);
    };
    var toastOptions = {
      message: 'クリックすると第一法規法情報総合データベース「D1-Law.com」（外部サイト）に移動します。',
      type: 'info',
      duration: 3000,
      dismissible: true
    };
    var isShowedOnce = false; // 一度表示されたら以降は表示しない
    /** 外部サイトへの移動である旨のtoast
     * （同種のtoastが複数表示されないように親コンポーネントで集中管理する） */
    var handleHoverD1LawReference = function handleHoverD1LawReference() {
      if (isShowedOnce) return;
      isShowedOnce = true;
      toast.open(toastOptions);
    };
    onMounted(function () {
      var _iterator = _createForOfIteratorHelper(props.watsonTabs),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _props$sentQuery4, _props$sentQuery4$fil, _props$sentQuery4$fil2;
          var defaultTab = _step.value;
          if ((_props$sentQuery4 = props.sentQuery) !== null && _props$sentQuery4 !== void 0 && (_props$sentQuery4$fil = _props$sentQuery4.filter) !== null && _props$sentQuery4$fil !== void 0 && (_props$sentQuery4$fil2 = _props$sentQuery4$fil.documentType) !== null && _props$sentQuery4$fil2 !== void 0 && _props$sentQuery4$fil2.includes(defaultTab)) {
            props.changeWatsonTab(defaultTab);
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
    var isIncluded = function isIncluded(tab) {
      var _props$sentQuery5, _props$sentQuery5$fil;
      if (!wandhGuideline) {
        if (tab === 'guideline' || tab === 'law') {
          return false;
        }
      }
      if (tab !== 'book' && tab !== 'case' && tab !== 'guideline' && tab !== 'law') {
        return false;
      }
      var docType = (_props$sentQuery5 = props.sentQuery) === null || _props$sentQuery5 === void 0 ? void 0 : (_props$sentQuery5$fil = _props$sentQuery5.filter) === null || _props$sentQuery5$fil === void 0 ? void 0 : _props$sentQuery5$fil.documentType;
      if (tab === 'law') {
        // 法令は法令導入前の履歴も含めて全てのチャットで表示する
        return true;
      } else if (tab === 'book') {
        // 判例導入前の履歴では、書籍は含まれているが判例は含まれていないと常に返す
        return !(docType && docType.length > 0) || docType.includes(tab);
      } else {
        // 書籍と判例以外が今後登場するときも判例同様の扱いでよいはず
        return Array.isArray(docType) && docType.includes(tab);
      }
    };
    return {
      showEvaluationWatsonDetails: showEvaluationWatsonDetails,
      handleShowDetails: handleShowDetails,
      sentQueryModel: sentQueryModel,
      publishedOnOrAfterYear: publishedOnOrAfterYear,
      decidedOnOrAfterYear: decidedOnOrAfterYear,
      genre: genre,
      bookReferences: bookReferences,
      caseReferences: caseReferences,
      guidelineReferences: guidelineReferences,
      tsutatsuReferences: tsutatsuReferences,
      tabKey: tabKey,
      tabName: tabName,
      tabClickHandler: tabClickHandler,
      handleHoverD1LawReference: handleHoverD1LawReference,
      isIncluded: isIncluded,
      getTabIcon: getTabIcon
    };
  }
});