<template>
  <v-rating v-model="model.score" length="5" dense color="teal" @click.native="onClick" />
</template>

<script lang="ts">
import { Component, Vue, Inject, InjectReactive, Watch, Prop } from 'nuxt-property-decorator';
import type { Evaluation } from 'wklr-backend-sdk/repos/labs';
import type {
  PostLabsWandhEvaluationParamsBodyWatson,
  PostLabsWandhEvaluationParamsBodyWatsonC,
} from 'wklr-backend-sdk/models';

@Component
export default class EvaluationWatsonStars extends Vue {
  @InjectReactive('evaluation') evaluation!: Required<Evaluation>;
  @Inject('postEvaluation') postEvaluation!: () => void;
  @InjectReactive('currentSessionID') currentSessionID!: string | null;

  @Prop()
  watsonType!: string;

  hasBeenClicked = false;

  get model(): PostLabsWandhEvaluationParamsBodyWatson | PostLabsWandhEvaluationParamsBodyWatsonC {
    if (this.watsonType === 'book') {
      return this.evaluation.watsonB;
    } else if (this.watsonType === 'case') {
      return this.evaluation.watsonC;
    } else {
      throw new Error('Invalid watsonType');
    }
  }

  onClick() {
    this.postEvaluation();

    // 初回クリック時は満点の場合はダイアログ表示しない
    if (this.model.score !== 5 || this.hasBeenClicked) {
      this.$emit('show-details');
    }

    this.hasBeenClicked = true;
  }

  @Watch('currentSessionID')
  onCurrentSessionIDChange() {
    this.hasBeenClicked = false;
  }
}
</script>
