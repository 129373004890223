import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import DocumentTypeIcon from '@/components/document-type-icon.vue';
import { useTelemetry } from '@/plugins/telemetry';
import { ImpressionDetector } from '@/utils/impressionUtils';
import { useAuth } from '@/plugins/auth';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
export default defineComponent({
  components: {
    DocumentTypeIcon: DocumentTypeIcon
  },
  props: {
    caseReference: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    selectedReference: {
      type: Object,
      default: null
    },
    handleSelectReference: {
      type: Function,
      required: true
    },
    answer: {
      type: [Array, String],
      required: true
    }
  },
  emits: ['hoverD1LawReference'],
  setup: function setup(props, ctx) {
    var telemetry = useTelemetry();
    var route = useRoute();
    var impressionDetector = ref();
    var auth = useAuth();
    var handleHover = function handleHover() {
      ctx.emit('hoverD1LawReference');
      props.handleSelectReference({
        type: DocumentTypeEnum.Case,
        document: props.index,
        section: null,
        focus: false,
        scroll: false
      });
      telemetry.sendHoverTelemetry({
        targetType: 'watson-reference-case-item',
        targetId: props.caseReference.record.id,
        params: {
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var handleClick = function handleClick() {
      telemetry.sendClickTelemetry({
        button: 'watson-reference-case-item',
        params: {
          targetId: props.caseReference.record.id,
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var handleSectionHover = function handleSectionHover(kind, section) {
      telemetry.sendHoverTelemetry({
        targetType: 'watson-reference-case-item__section',
        targetId: props.caseReference.record.id,
        params: {
          kind: kind,
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          section: section,
          // 文献の中で何番目に表示されているセクションか
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var handleSectionClick = function handleSectionClick(kind, section) {
      telemetry.sendClickTelemetry({
        button: 'watson-reference-case-item__section',
        params: {
          targetId: props.caseReference.record.id,
          kind: kind,
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          section: section,
          // 文献の中で何番目に表示されているセクションか
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var isCitedInHolmes = computed(function () {
      if (!Array.isArray(props.answer)) {
        return false;
      }
      return props.answer.some(function (answer) {
        return answer.ref && answer.ref.some(function (r) {
          return r.document === props.index;
        });
      });
    });
    var isExpanded = ref(false);
    var subitems = computed(function () {
      var refIndex = props.caseReference.references[0].refIndex;
      var youshiShown = props.caseReference.references[0].youshi.shown.map(function (item, subIndex) {
        return _objectSpread(_objectSpread({}, item), {}, {
          kind: 'youshi',
          label: "\u8981\u65E8 ".concat(item.youshiIndex + 1),
          subIndex: subIndex
        });
      });
      var youshiHidden = props.caseReference.references[0].youshi.hidden.map(function (item, subIndex) {
        return _objectSpread(_objectSpread({}, item), {}, {
          kind: 'youshi',
          label: "\u8981\u65E8 ".concat(item.youshiIndex + 1),
          subIndex: subIndex + youshiShown.length
        });
      });
      var jikou = props.caseReference.references[0].jikou.map(function (item, subIndex) {
        return _objectSpread(_objectSpread({}, item), {}, {
          kind: 'jikou',
          label: "\u5224\u793A\u4E8B\u9805\u7B49 ".concat(subIndex + 1),
          subIndex: subIndex + youshiShown.length + youshiHidden.length
        });
      });
      return {
        refIndex: refIndex,
        youshiShown: youshiShown,
        hidden: [].concat(_toConsumableArray(youshiHidden), _toConsumableArray(jikou))
      };
    });
    var d1Option = computed(function () {
      return auth.permissions.labsQAD1;
    });
    var card = ref(null);
    onMounted(function () {
      if (!card.value) {
        return;
      }
      console.log('card captured');
      impressionDetector.value = new ImpressionDetector(telemetry, route, card.value,
      // 20% 以上の領域が 3000 ミリ秒以上表示されたらインプレッションイベントを送る
      0.2, 3000, "watson-reference-case-item", props.caseReference.record.id);
    });
    onBeforeUnmount(function () {
      var _impressionDetector$v;
      (_impressionDetector$v = impressionDetector.value) === null || _impressionDetector$v === void 0 ? void 0 : _impressionDetector$v.cleanup();
    });
    return {
      isExpanded: isExpanded,
      subitems: subitems,
      handleHover: handleHover,
      handleClick: handleClick,
      handleSectionHover: handleSectionHover,
      handleSectionClick: handleSectionClick,
      d1Option: d1Option
    };
  }
});