var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{class:{
    select:
      _vm.selectedReference &&
      _vm.selectedReference.document == _vm.reference.document &&
      (_vm.selectedReference.section == _vm.reference.section || _vm.selectedReference.section === null) &&
      _vm.selectedReference.type !== 'law',
    'd-block': _vm.showDetailedReference,
  },on:{"click":function($event){return _vm.handleClick(_vm.reference)}}},[_c('span',{staticClass:"reference-text"},[_c('document-type-icon',{attrs:{"type":_vm.type}}),_vm._v(_vm._s(_vm.showDetailedReference ? _vm.reference.detailedDisplayID : _vm.reference.displayID)+"\n  ")],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }