var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{ref:"referenceColumn",staticClass:"result-column watson",attrs:{"cols":"12","md":"7"},on:{"mouseenter":_vm.handleUnfocusReference}},[_c('v-slide-y-transition',[(_vm.state === 'watson-thinking')?_c('p',{key:"watson-thinking",staticClass:"to-fade text-center"},[_c('watson-icon',{staticClass:"my-8"}),_c('br'),_vm._v(" "),_c('nekomimi',{staticClass:"mr-4"}),_vm._v(" Watson is searching for references...\n    ")],1):_vm._e()]),_vm._v(" "),(_vm.state !== 'watson-thinking')?_c('div',{staticStyle:{"height":"100%"}},[_c('v-tabs',{staticClass:"watson__tabs",attrs:{"hide-slider":""},model:{value:(_vm.tabKey),callback:function ($$v) {_vm.tabKey=$$v},expression:"tabKey"}},_vm._l((_vm.watsonTabs.filter(_vm.isIncluded)),function(tab){return _c('v-tab',{key:tab,staticClass:"watson__tabs__tab",on:{"click":function($event){return _vm.tabClickHandler(tab)}}},[_c('img',{staticClass:"watson__tabs__tab__icon",attrs:{"src":_vm.getTabIcon(tab)}}),_vm._v(" "),_c('div',{staticClass:"watson__tabs__tab__name"},[_vm._v(_vm._s(_vm.tabName(tab)))])])}),1),_vm._v(" "),_c('v-divider',{staticClass:"ml-4 mr-4"}),_vm._v(" "),_c('div',{ref:"tabsItems",staticClass:"tabs-items"},[_c('watson-tab',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.watsonTab === 'book'),expression:"watsonTab === 'book'"}],scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.publishedOnOrAfterYear))])]},proxy:true},{key:"feedback",fn:function(){return [_c('evaluation-watson-stars',_vm._b({on:{"show-details":_vm.handleShowDetails}},'evaluation-watson-stars',{ watsonType: 'book' },false))]},proxy:true},{key:"referenceItems",fn:function(){return _vm._l((_vm.bookReferences),function({ index, ...reference }){return _c('watson-reference-item',_vm._b({key:reference.record.id,ref:"referenceItems",refInFor:true},'watson-reference-item',{
              selectedReference: _vm.selectedReference,
              handleSelectReference: _vm.handleSelectReference,
              reference,
              index,
              answer: _vm.answer,
            },false))})},proxy:true}],null,false,51688130)},'watson-tab',{
          tabType: 'book',
          tabName: _vm.tabName('book'),
          modelDisplayName: _vm.sentQueryModel?.displayName.watsonB,
          isFound: _vm.bookReferences.length > 0,
        },false)),_vm._v(" "),_c('watson-tab',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.watsonTab === 'case'),expression:"watsonTab === 'case'"}],scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.decidedOnOrAfterYear))])]},proxy:true},{key:"feedback",fn:function(){return [_c('evaluation-watson-stars',_vm._b({on:{"show-details":_vm.handleShowDetails}},'evaluation-watson-stars',{ watsonType: 'case' },false))]},proxy:true},{key:"referenceItems",fn:function(){return _vm._l((_vm.caseReferences),function({ index, ...caseReference }){return _c('watson-reference-case-item',_vm._b({key:caseReference.record.id,ref:"referenceItems",refInFor:true,on:{"hoverD1LawReference":_vm.handleHoverD1LawReference}},'watson-reference-case-item',{
              caseReference,
              index,
              selectedReference: _vm.selectedReference,
              handleSelectReference: _vm.handleSelectReference,
              answer: _vm.answer,
            },false))})},proxy:true}],null,false,433104289)},'watson-tab',{
          tabType: 'case',
          tabName: _vm.tabName('case'),
          modelDisplayName: _vm.sentQueryModel?.displayName.watsonC,
          isFound: _vm.caseReferences.length > 0,
        },false)),_vm._v(" "),_c('watson-tab',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.watsonTab === 'guideline'),expression:"watsonTab === 'guideline'"}],scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.publishedOnOrAfterYear))])]},proxy:true},{key:"referenceItems",fn:function(){return _vm._l((_vm.guidelineReferences.concat(_vm.tsutatsuReferences)),function({ index, ...reference }){return _c('watson-reference-guideline-item',_vm._b({key:reference.record.id,ref:"referenceItems",refInFor:true},'watson-reference-guideline-item',{
              reference,
              index,
              answer: _vm.answer,
              selectedReference: _vm.selectedReference,
              handleSelectReference: _vm.handleSelectReference,
            },false))})},proxy:true}],null,false,1661260955)},'watson-tab',{
          tabType: 'guideline',
          tabName: _vm.tabName('guideline'),
          modelDisplayName: _vm.sentQueryModel?.displayName.watsonG,
          isFound: _vm.guidelineReferences.length + _vm.tsutatsuReferences.length > 0,
        },false)),_vm._v(" "),_c('watson-tab',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$wandhGuideline && _vm.watsonTab === 'law'),expression:"$wandhGuideline && watsonTab === 'law'"}],scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.publishedOnOrAfterYear))])]},proxy:true},{key:"referenceItems",fn:function(){return _vm._l((_vm.laws),function(law,i){return _c('watson-law-item',_vm._b({key:i,ref:"lawItems",refInFor:true},'watson-law-item',{
              law,
              index: i,
              answer: _vm.answer,
              selectedReference: _vm.selectedReference,
              handleSelectReference: _vm.handleSelectReference,
            },false))})},proxy:true}],null,false,117039767)},'watson-tab',{
          tabType: 'law',
          tabName: _vm.tabName('law'),
          isFound: Array.isArray(_vm.laws) && _vm.laws.length > 0,
        },false))],1)],1):_vm._e(),_vm._v(" "),_c('evaluation-watson-details',_vm._b({model:{value:(_vm.showEvaluationWatsonDetails),callback:function ($$v) {_vm.showEvaluationWatsonDetails=$$v},expression:"showEvaluationWatsonDetails"}},'evaluation-watson-details',{ watsonType: _vm.watsonTab, bookReferences: _vm.bookReferences, caseReferences: _vm.caseReferences },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }