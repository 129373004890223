import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCol, {
    ref: "answerColumn",
    staticClass: "result-column",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "question-balloon"
  }, [_vm._v("\n    " + _vm._s(_vm.sentQuery.question) + "\n  ")]), _vm._v(" "), _c(VSlideYTransition, [_vm.state !== 'idle' && !_vm.answer ? _c('p', {
    staticClass: "to-fade text-center"
  }, [_c('holmes-icon', {
    staticClass: "my-8"
  }), _c('br'), _vm._v(" "), _c('nekomimi', {
    staticClass: "mr-4"
  }), _vm._v(" Holmes is thinking...\n    ")], 1) : _vm.answer === 'skipped' ? _c(VAlert, {
    staticClass: "mt-8",
    attrs: {
      "type": "info",
      "outlined": ""
    }
  }, [_vm._v("\n      組織管理者の設定により、Holmesの結果は生成されません。\n    ")]) : _vm.answer ? _c('div', [_c('div', {
    staticClass: "answer-balloon-name mb-1 mr-4 d-flex align-center"
  }, [_c(VAvatar, {
    staticClass: "mr-1",
    attrs: {
      "color": "orange",
      "size": "32"
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-smoking-pipe")])], 1), _vm._v(" "), _c('div', {
    staticClass: "answer-balloon-model"
  }, [_c('div', [_vm._v(_vm._s(_vm.sentQueryModel.displayName.holmes) + " による生成")]), _vm._v(" "), _c('div', {
    staticClass: "detailed-reference-option"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showDetailedReference,
      expression: "showDetailedReference"
    }],
    attrs: {
      "id": "showDetailedReference",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.showDetailedReference) ? _vm._i(_vm.showDetailedReference, null) > -1 : _vm.showDetailedReference
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.showDetailedReference,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.showDetailedReference = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.showDetailedReference = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.showDetailedReference = $$c;
        }
      }
    }
  }), _vm._v(" "), _c('label', {
    attrs: {
      "for": "showDetailedReference"
    }
  }, [_vm._v("文献名を表示")])])]), _vm._v(" "), _c(VFadeTransition, [_vm.state === 'idle' ? _c('evaluation-holmes-good-bad', {
    attrs: {
      "index": 1
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "answer-balloon mr-4"
  }, [_vm.answer === 'N/A' || Array.isArray(_vm.answer) && _vm.answer.length === 0 || _vm.answer === null ? [_c('p', [_vm._v("文献を参照しましたが、回答が分かりませんでした。")]), _vm._v(" "), _c(VAlert, {
    staticClass: "mt-8",
    attrs: {
      "type": "info",
      "outlined": ""
    }
  }, [_vm._v("\n            実務書に記載のありそうな表現を使って質問を入力すると、回答が得られる可能性が高まります。\n          ")])] : _vm._e(), _vm._v(" "), _vm._l(_vm.answerTimered, function (_ref, i) {
    var text = _ref.text,
      ref = _ref.ref;
    return _c('div', {
      key: i,
      staticClass: "answer-container"
    }, [_c('p', {
      staticClass: "answer-paragraph"
    }, [_c('span', {
      staticClass: "answer-text"
    }, [_vm._v(_vm._s(text))]), _vm._v(" "), _vm.state !== 'idle' && i === _vm.answerTimered.length - 1 ? _c('span', {
      staticClass: "blinking-cursor"
    }) : _vm._e()]), _vm._v(" "), ref ? _c('p', {
      staticClass: "d-inline-block mb-0"
    }, [_vm._l(ref, function (refItem) {
      return [_c('holmes-reference-marker', _vm._b({
        key: refItem.id,
        staticClass: "mr-2"
      }, 'holmes-reference-marker', {
        reference: refItem,
        selectedReference: _vm.selectedReference,
        handleSelectReference: _vm.handleSelectReference,
        references: _vm.references,
        showDetailedReference: _vm.showDetailedReference
      }, false))];
    })], 2) : _vm._e()]);
  })], 2), _vm._v(" "), _vm.laws && _vm.laws.length > 0 && _vm.$wandhGuideline ? [_c('div', {
    staticClass: "laws-balloon mr-4"
  }, [_c('div', {
    staticClass: "law-items"
  }, [_c('span', {
    staticClass: "law-title"
  }, [_c('document-type-icon', {
    attrs: {
      "type": "law"
    }
  }), _vm._v(" "), _c('div', [_vm._v("関連法令")])], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.laws, function (law, i) {
    return _c(VChip, {
      key: i,
      staticClass: "law-chip",
      class: {
        select: _vm.selectedReference && _vm.selectedReference.type === 'law' && _vm.selectedReference.document == i
      },
      attrs: {
        "label": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickOnLaw(i);
        }
      }
    }, [_vm._v("\n                " + _vm._s("".concat(law.title, " ").concat(law.label.replace(/\s*[（(][^）)]*[）)]\s*/g, ''))) + "\n              ")]);
  }), 1)])])] : _vm._e(), _vm._v(" "), _vm.state === 'idle' ? _c('div', {
    staticClass: "text-caption text--disabled text-center"
  }, [_vm._v("\n        システムによる自動生成です。内容の正確性は必ずご確認ください。\n      ")]) : _vm._e()], 2) : _vm._e()], 1), _vm._v(" "), _vm.error ? _c(VAlert, {
    staticClass: "mt-8",
    attrs: {
      "type": "error",
      "outlined": ""
    }
  }, [_vm._v("\n    エラーが発生しました。恐れ入りますが、時間をおいてもう一度お試しください。\n  ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };