<template>
  <div>
    <v-btn
      icon
      color="primary"
      @click="set(model.score === 1 ? { score: undefined } : { score: 1, reasons: undefined })"
    >
      <v-icon v-if="model.score === 1" small>mdi-thumb-up</v-icon>
      <v-icon v-else small>mdi-thumb-up-outline</v-icon>
    </v-btn>
    <v-menu offset-y :close-on-content-click="false" :open-on-click="model.score !== -1">
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click="set(model.score === -1 ? { score: undefined, reasons: undefined } : { score: -1 })"
        >
          <v-icon v-if="model.score === -1" small>mdi-thumb-down</v-icon>
          <v-icon v-else small>mdi-thumb-down-outline</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group
          :value="model.reasons"
          multiple
          @change="set({ reasons: $event.length > 0 ? $event : undefined })"
        >
          <v-list-item v-for="(reason, index) in ReasonsPreset" :key="index" :value="reason">
            <template #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ reason }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex pt-2">
              <v-text-field
                v-model.trim="freeInputEditing"
                hide-details
                dense
                outlined
                placeholder="その他（自由記入）"
                @keyup.enter="onFreeInputSave"
              />
              <v-btn class="ml-2" @click="onFreeInputSave">
                <v-icon v-if="freeInputEditing === model.comment" left>mdi-check</v-icon>
                <v-icon v-else-if="freeInputEditing" left color="red" title="未保存の内容があります">
                  mdi-information
                </v-icon>
                保存
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Inject, InjectReactive, Prop } from 'nuxt-property-decorator';
import type { Evaluation } from 'wklr-backend-sdk/repos/labs';
import type { RecursivePartial } from '@/types/RecursivePartial';

@Component
export default class EvaluationHolmesGoodBad extends Vue {
  readonly ReasonsPreset = [
    '論点がずれ、回答になっていない',
    '漏れがある',
    '参考文献と食い違いがある',
    '回答内で矛盾がある',
    '日本語としておかしい',
  ];

  @InjectReactive('evaluation') evaluation!: Evaluation;
  @Inject('postEvaluation') postEvaluation!: () => void;
  @Prop() index!: number;

  freeInputEditing = '';

  get model(): NonNullable<EvaluationHolmesGoodBad['_model']> {
    return this._model ?? this._initial;
  }

  set(value: RecursivePartial<EvaluationHolmesGoodBad['model']>) {
    if (this._model) {
      Object.assign(this._model, value);
      this.evaluation.holmes.generations.push();
    } else {
      this.evaluation.holmes.generations.push(Object.assign({}, this._initial, value));
    }

    this.postEvaluation();
  }

  get _model() {
    return this.evaluation.holmes.generations.find(({ index }) => index === this.index);
  }

  get _initial() {
    return { index: this.index, reasons: [] };
  }

  onFreeInputSave() {
    this.set({ comment: this.freeInputEditing || undefined });
  }
}
</script>
