import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "max-width": "960"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "headline grey lighten-2"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_vm._v(_vm._s(_vm.watsonName) + " の参考候補リストへのフィードバック")]), _vm._v(" "), _c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-close")])], 1)], 1), _vm._v(" "), _c(VCardText, {
    staticClass: "pt-4 pb-8"
  }, [_c(VRow, {
    attrs: {
      "align": "center"
    }
  }, [_c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("総合評価")]), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c(VRating, {
    attrs: {
      "large": "",
      "dense": "",
      "length": "5",
      "color": "teal"
    },
    on: {
      "input": function input($event) {
        return _vm.postEvaluation();
      }
    },
    model: {
      value: _vm.model.score,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "score", $$v);
      },
      expression: "model.score"
    }
  })], 1)], 1), _vm._v(" "), _c(VRow, {
    attrs: {
      "align": "center"
    }
  }, [_c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("リストに足りない" + _vm._s(_vm.documentTypeName) + "・論点のご提案")]), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c(VTextField, {
    attrs: {
      "value": _vm.model.missing,
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "placeholder": "例：××法の定番としてまずは『〇〇』が出ると良い"
    },
    on: {
      "input": _vm.onInputMissing
    }
  })], 1)], 1), _vm._v(" "), _c(VRow, [_c(VCol, {
    staticClass: "text-right pt-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("個別の" + _vm._s(_vm.documentTypeName) + "選定への評価")]), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, _vm._l(_vm.Reasons, function (reason) {
    return _c('div', {
      key: reason
    }, [_c(VCheckbox, {
      attrs: {
        "hide-details": "",
        "dense": "",
        "label": reason,
        "value": reason
      },
      model: {
        value: _vm.impressionReasons,
        callback: function callback($$v) {
          _vm.impressionReasons = $$v;
        },
        expression: "impressionReasons"
      }
    }), _vm._v(" "), _c(VSlideYTransition, [_vm.impressionReasons.includes(reason) ? _c(VList, {
      staticClass: "ml-4",
      attrs: {
        "dense": ""
      }
    }, [_c(VListItemGroup, {
      attrs: {
        "multiple": ""
      },
      model: {
        value: _vm.impressionDocuments[reason],
        callback: function callback($$v) {
          _vm.$set(_vm.impressionDocuments, reason, $$v);
        },
        expression: "impressionDocuments[reason]"
      }
    }, _vm._l(_vm.references, function (ref) {
      return _c(VListItem, {
        key: ref.record.id,
        attrs: {
          "value": ref.record.id
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref) {
            var active = _ref.active;
            return [_c(VListItemAction, [_c(VCheckbox, {
              attrs: {
                "input-value": active
              }
            })], 1), _vm._v(" "), _c(VListItemContent, [_c(VListItemTitle, [_vm._v("\n                          " + _vm._s(_vm.referenceItemName(ref)) + "\n                        ")])], 1)];
          }
        }], null, true)
      });
    }), 1)], 1) : _vm._e()], 1)], 1);
  }), 0)], 1), _vm._v(" "), _c(VRow, [_c(VCol, {
    staticClass: "text-right pt-5",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("ご意見（自由記入）")]), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "8"
    }
  }, [_c(VTextarea, {
    attrs: {
      "value": _vm.model.comment,
      "outlined": "",
      "hide-details": "",
      "rows": "3"
    },
    on: {
      "input": _vm.onInputComment
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };