/** ストリーミングされる、かつLLMの出力ゆえ本当にJSONか分からないJSON文字列をパーズする */
export function parseStreamingJSON(text: string): Paragraph[] {
  // console.log('Parsing text:', text);
  text = text
    .trim()
    .replace(/\}\],\s*\[?\{/g, '},{')
    .replace(/^```json\s*|\s*```$/, '')
    // 稀に{{}}での出力がある
    .replace(/{{/g, '{')
    .replace(/}}/g, '}');

  if (!text) {
    return [];
  }

  try {
    // とりあえず一回やってみる
    const parsed = JSON.parse(text);

    if (Array.isArray(parsed)) {
      // console.log('parseStreamingJSON 1', parsed);
      return parsed
        .map((x) => {
          if (isParagraph(x)) {
            return x;
          } else if (x && typeof x === 'string') {
            return { text: x, ref: null };
          } else {
            return null;
          }
        })
        .filter((x): x is NonNullable<typeof x> => Boolean(x));
    } else if (isParagraph(parsed)) {
      // console.log('parseStreamingJSON 2', parsed);
      return [parsed];
    }
  } catch {
    /* no-op */
  }

  const match = text.match(parseStreamingJSONRegExp);

  if (!match || !match.groups) {
    // 全くJSONとは考えられないのでそのままテキストとして表示する
    return [{ text, ref: null }];
  }

  const result: Paragraph[] = [];

  if (match.groups.frontGarbage?.length > 5) {
    // 何か分からないが一応表示しておく
    result.push({ text: match.groups.frontGarbage, ref: null });
  }

  const list = (match.groups.list1 || match.groups.list2 || match.groups.list3)?.replace(/,\s*$/, '');
  if (list) {
    result.push(...JSON.parse('[' + list + ']'));
  }

  const incomplete = match.groups.incomplete1 || match.groups.incomplete2;
  // console.log('parseStreamingJSON incomplete:', incomplete);
  if (incomplete && incomplete !== '{') {
    const tryParse = (str: string) => {
      try {
        return JSON.parse(str);
      } catch {
        return null;
      }
    };

    // }を補完してパースする
    let completed = tryParse(`${incomplete}}`);

    if (!completed) {
      // ","ref": null}を補完してパースする
      completed = tryParse(`${incomplete}","ref": null}`);
    }

    if (completed) {
      if (!completed.ref) {
        // refがない場合はnullを入れておく
        completed.ref = null;
      }
    } else {
      // refの出力中であることを想定して]を補完してパースする
      // 全くの見当違いであっても最後のfilterで除外されるだろう
      completed = incomplete.endsWith(',') ? tryParse(`${incomplete.slice(0, -1)}]}`) : tryParse(`${incomplete}]}`);
    }

    if (completed) {
      result.push(completed);
    }
    // pushできていなくても次回続きがくればパースできるだろうから、諦めて捨てる
  }

  if (match.groups.backGarbage?.length > 5) {
    // 何か分からないが一応表示しておく
    result.push({ text: match.groups.backGarbage, ref: null });
  }

  // console.log('parseStreamingJSON before final filter:', result);
  const resultFinal = result.filter(isParagraph);
  // console.log('parseStreamingJSON after final filter:', resultFinal);
  return resultFinal;
  return result.filter(isParagraph);
}

const parseStreamingJSONRegExp = (() => {
  const maybeSpace = '\\s*';
  const maybe = (x: string) => '(?:' + x + ')?';
  const oneOf = (...xs: string[]) => '(?:' + xs.join('|') + ')';
  const repeat = (x: string) => '(?:' + x + ')*';
  const listOf = (x: string) => repeat(x + maybeSpace + ',' + maybeSpace) + x;
  const capture = (name: string, x: string) => '(?<' + name + '>' + x + ')';
  const string = '"(?:[^"\\n]|\\\\.|\\n)*"'; // multi-line string
  const int = '[0-9]+';
  const intList = '\\[' + maybeSpace + listOf(int) + maybeSpace + '\\]';
  const key = '"[a-z]+"';
  const nullish = 'null';
  const keyAndValue = key + maybeSpace + ':' + maybeSpace + oneOf(string, int, intList, nullish);
  const object = '\\{' + maybeSpace + listOf(keyAndValue) + maybeSpace + '\\}';
  const incompleteKey = '"[a-z]*';
  const incompleteString = '"(?:[^"\\n]|\\\\.|\\n)*';
  const incompleteIntList =
    '\\[' +
    maybeSpace +
    oneOf(
      '',
      repeat(int + maybeSpace + ',' + maybeSpace), // [1,2,
      repeat(int + maybeSpace + ',' + maybeSpace) + int + maybeSpace, // [1,2,3
    );
  const incompleteNull = oneOf('n', 'nu', 'nul');
  const incompleteObject =
    '\\{' +
    maybeSpace +
    repeat(keyAndValue + maybeSpace + ',' + maybeSpace) +
    maybeSpace +
    maybe(
      oneOf(
        incompleteKey,
        key +
          maybeSpace +
          maybe(
            ':' +
              maybeSpace +
              oneOf(
                '',
                int,
                incompleteNull + maybe('l'),
                incompleteString + maybe('"'),
                incompleteIntList + maybe(']'),
              ),
          ),
      ),
    );
  return new RegExp(
    '^' +
      maybeSpace +
      capture('frontGarbage', '.*?') +
      maybeSpace +
      '\\[' +
      maybeSpace +
      maybe(
        oneOf(
          capture('incomplete1', incompleteObject),
          capture('list1', listOf(object)) + maybeSpace + maybe(',' + maybeSpace),
          capture('list2', listOf(object)) + maybeSpace + ',' + maybeSpace + capture('incomplete2', incompleteObject),
          capture('list3', listOf(object)) +
            maybeSpace +
            '\\]' +
            maybeSpace +
            capture('backGarbage', '.*?') +
            maybeSpace,
        ),
      ) +
      '$',
  );
})();

// interface Paragraph {
//   text: string;
//   ref?: number;
// }
interface Paragraph {
  text: string;
  ref: number | number[] | null;
}

function isParagraph(x: unknown): x is Paragraph {
  return Boolean(
    x &&
      typeof x === 'object' &&
      'text' in x &&
      typeof x.text === 'string' &&
      isValidText(x.text) &&
      'ref' in x &&
      (x.ref == null ||
        typeof x.ref === 'number' ||
        (Array.isArray(x.ref) && x.ref.every((r) => typeof r === 'number')) ||
        x.ref === null),
  );
}

function isValidText(text: unknown): text is string {
  return Boolean(text && typeof text === 'string' && !text.startsWith('['));
}
