export const Court2Rank = Object.freeze({
  最高裁判所: 0,
  最高裁判所大法廷: 0,
  最高裁判所第一小法廷: 0,
  最高裁判所第二小法廷: 0,
  最高裁判所第三小法廷: 0,
  東京高等裁判所: 1,
  大阪高等裁判所: 1,
  知的財産高等裁判所: 1,
  高等裁判所: 4,
  札幌高等裁判所: 4,
  仙台高等裁判所: 4,
  名古屋高等裁判所: 4,
  広島高等裁判所: 4,
  高松高等裁判所: 4,
  福岡高等裁判所: 4,
  関東高等法院: 4,
  関東高等法院覆審部: 4,
  札幌高等裁判所全支部: 5,
  札幌高等裁判所函館支部: 5,
  札幌高等裁判所旭川支部: 5,
  仙台高等裁判所全支部: 5,
  仙台高等裁判所秋田支部: 5,
  名古屋高等裁判所全支部: 5,
  名古屋高等裁判所金沢支部: 5,
  広島高等裁判所全支部: 5,
  広島高等裁判所松江支部: 5,
  広島高等裁判所岡山支部: 5,
  広島高等裁判所米子支部: 5,
  福岡高等裁判所全支部: 5,
  福岡高等裁判所宮崎支部: 5,
  福岡高等裁判所那覇支部: 5,
  大審院: 5,
  東京地方裁判所: 6,
  国税不服審判所: 6,
  大阪法務局: 6,
  札幌控訴院: 6,
  函館控訴院: 6,
  宮城控訴院: 6,
  東京控訴院: 6,
  名古屋控訴院: 6,
  大阪控訴院: 6,
  広島控訴院: 6,
  長崎控訴院: 6,
  特許庁: 6,
  公正取引委員会: 6,
  東京民事地方裁判所: 6,
  東京刑事地方裁判所: 6,
  公害等調整委員会: 6,
  関東庁覆審法院: 6,
  木浦理事庁: 6,
  大阪地方裁判所: 7,
  東京地方裁判所全支部: 8,
  東京地方裁判所八王子支部: 8,
  東京地方裁判所立川支部: 8,
  大阪地方裁判所全支部: 9,
  大阪地方裁判所堺支部: 9,
  大阪地方裁判所岸和田支部: 9,
  地方裁判所: 10,
  旭川地方裁判所: 10,
  釧路地方裁判所: 10,
  札幌地方裁判所: 10,
  函館地方裁判所: 10,
  青森地方裁判所: 10,
  盛岡地方裁判所: 10,
  仙台地方裁判所: 10,
  秋田地方裁判所: 10,
  山形地方裁判所: 10,
  福島地方裁判所: 10,
  水戸地方裁判所: 10,
  宇都宮地方裁判所: 10,
  前橋地方裁判所: 10,
  浦和地方裁判所: 10,
  千葉地方裁判所: 10,
  横浜地方裁判所: 10,
  新潟地方裁判所: 10,
  富山地方裁判所: 10,
  金沢地方裁判所: 10,
  福井地方裁判所: 10,
  甲府地方裁判所: 10,
  長野地方裁判所: 10,
  岐阜地方裁判所: 10,
  静岡地方裁判所: 10,
  名古屋地方裁判所: 10,
  津地方裁判所: 10,
  安濃津地方裁判所: 10,
  大津地方裁判所: 10,
  京都地方裁判所: 10,
  神戸地方裁判所: 10,
  奈良地方裁判所: 10,
  和歌山地方裁判所: 10,
  鳥取地方裁判所: 10,
  松江地方裁判所: 10,
  岡山地方裁判所: 10,
  広島地方裁判所: 10,
  山口地方裁判所: 10,
  徳島地方裁判所: 10,
  高松地方裁判所: 10,
  松山地方裁判所: 10,
  高知地方裁判所: 10,
  福岡地方裁判所: 10,
  佐賀地方裁判所: 10,
  長崎地方裁判所: 10,
  熊本地方裁判所: 10,
  大分地方裁判所: 10,
  宮崎地方裁判所: 10,
  鹿児島地方裁判所: 10,
  那覇地方裁判所: 10,
  樺太地方裁判所: 10,
  さいたま地方裁判所: 10,
  京城地方院: 10,
  関東地方院: 10,
  神奈川県地方労働委員会: 10,
  国地方係争処理委員会: 10,
  旭川地方裁判所全支部: 11,
  旭川地方裁判所帯広支部: 11,
  旭川地方裁判所稚内支部: 11,
  旭川地方裁判所留萌支部: 11,
  旭川地方裁判所紋別支部: 11,
  旭川地方裁判所名寄支部: 11,
  釧路地方裁判所全支部: 11,
  釧路地方裁判所網走支部: 11,
  釧路地方裁判所北見支部: 11,
  釧路地方裁判所根室支部: 11,
  釧路地方裁判所帯広支部: 11,
  札幌地方裁判所全支部: 11,
  札幌地方裁判所岩内支部: 11,
  札幌地方裁判所小樽支部: 11,
  札幌地方裁判所浦河支部: 11,
  札幌地方裁判所滝川支部: 11,
  札幌地方裁判所岩見沢支部: 11,
  札幌地方裁判所室蘭支部: 11,
  札幌地方裁判所苫小牧支部: 11,
  函館地方裁判所全支部: 11,
  函館地方裁判所寿都支部: 11,
  函館地方裁判所江差支部: 11,
  青森地方裁判所全支部: 11,
  青森地方裁判所八戸支部: 11,
  青森地方裁判所弘前支部: 11,
  青森地方裁判所五所川原支部: 11,
  青森地方裁判所鰺ケ沢支部: 11,
  青森地方裁判所十和田支部: 11,
  盛岡地方裁判所全支部: 11,
  盛岡地方裁判所花巻支部: 11,
  盛岡地方裁判所二戸支部: 11,
  盛岡地方裁判所遠野支部: 11,
  盛岡地方裁判所宮古支部: 11,
  盛岡地方裁判所一関支部: 11,
  盛岡地方裁判所水沢支部: 11,
  仙台地方裁判所全支部: 11,
  仙台地方裁判所古川支部: 11,
  仙台地方裁判所大河原支部: 11,
  仙台地方裁判所石巻支部: 11,
  仙台地方裁判所登米支部: 11,
  仙台地方裁判所気仙沼支部: 11,
  秋田地方裁判所全支部: 11,
  秋田地方裁判所大館支部: 11,
  秋田地方裁判所能代支部: 11,
  秋田地方裁判所本荘支部: 11,
  秋田地方裁判所横手支部: 11,
  秋田地方裁判所湯沢支部: 11,
  秋田地方裁判所大曲支部: 11,
  山形地方裁判所全支部: 11,
  山形地方裁判所米沢支部: 11,
  山形地方裁判所鶴岡支部: 11,
  山形地方裁判所新庄支部: 11,
  山形地方裁判所酒田支部: 11,
  福島地方裁判所全支部: 11,
  福島地方裁判所郡山支部: 11,
  福島地方裁判所平支部: 11,
  福島地方裁判所会津若松支部: 11,
  福島地方裁判所相馬支部: 11,
  福島地方裁判所白河支部: 11,
  福島地方裁判所いわき支部: 11,
  福島地方裁判所若松支部: 11,
  水戸地方裁判所全支部: 11,
  水戸地方裁判所土浦支部: 11,
  水戸地方裁判所下妻支部: 11,
  水戸地方裁判所日立支部: 11,
  水戸地方裁判所常陸太田支部: 11,
  水戸地方裁判所麻生支部: 11,
  水戸地方裁判所龍ケ崎支部: 11,
  水戸地方裁判所太田支部: 11,
  宇都宮地方裁判所全支部: 11,
  宇都宮地方裁判所足利支部: 11,
  宇都宮地方裁判所栃木支部: 11,
  宇都宮地方裁判所真岡支部: 11,
  宇都宮地方裁判所大田原支部: 11,
  前橋地方裁判所全支部: 11,
  前橋地方裁判所高崎支部: 11,
  前橋地方裁判所桐生支部: 11,
  前橋地方裁判所太田支部: 11,
  前橋地方裁判所沼田支部: 11,
  前橋地方裁判所富岡支部: 11,
  前橋地方裁判所中之条支部: 11,
  浦和地方裁判所全支部: 11,
  浦和地方裁判所川越支部: 11,
  浦和地方裁判所越谷支部: 11,
  浦和地方裁判所熊谷支部: 11,
  浦和地方裁判所秩父支部: 11,
  千葉地方裁判所全支部: 11,
  千葉地方裁判所一宮支部: 11,
  千葉地方裁判所佐倉支部: 11,
  千葉地方裁判所松戸支部: 11,
  千葉地方裁判所木更津支部: 11,
  千葉地方裁判所館山支部: 11,
  千葉地方裁判所八日市場支部: 11,
  千葉地方裁判所佐原支部: 11,
  横浜地方裁判所全支部: 11,
  横浜地方裁判所横須賀支部: 11,
  横浜地方裁判所川崎支部: 11,
  横浜地方裁判所小田原支部: 11,
  横浜地方裁判所相模原支部: 11,
  新潟地方裁判所全支部: 11,
  新潟地方裁判所三条支部: 11,
  新潟地方裁判所長岡支部: 11,
  新潟地方裁判所新発田支部: 11,
  新潟地方裁判所柏崎支部: 11,
  新潟地方裁判所六日町支部: 11,
  新潟地方裁判所村上支部: 11,
  新潟地方裁判所高田支部: 11,
  新潟地方裁判所糸魚川支部: 11,
  新潟地方裁判所佐渡支部: 11,
  新潟地方裁判所相川支部: 11,
  富山地方裁判所全支部: 11,
  富山地方裁判所高岡支部: 11,
  富山地方裁判所魚津支部: 11,
  富山地方裁判所砺波支部: 11,
  金沢地方裁判所全支部: 11,
  金沢地方裁判所小松支部: 11,
  金沢地方裁判所七尾支部: 11,
  金沢地方裁判所輪島支部: 11,
  福井地方裁判所全支部: 11,
  福井地方裁判所敦賀支部: 11,
  福井地方裁判所武生支部: 11,
  福井地方裁判所大野支部: 11,
  福井地方裁判所小浜支部: 11,
  甲府地方裁判所全支部: 11,
  甲府地方裁判所鰍沢支部: 11,
  甲府地方裁判所谷村支部: 11,
  甲府地方裁判所都留支部: 11,
  長野地方裁判所全支部: 11,
  長野地方裁判所上田支部: 11,
  長野地方裁判所諏訪支部: 11,
  長野地方裁判所松本支部: 11,
  長野地方裁判所大町支部: 11,
  長野地方裁判所飯山支部: 11,
  長野地方裁判所佐久支部: 11,
  長野地方裁判所木曾支部: 11,
  長野地方裁判所飯田支部: 11,
  長野地方裁判所伊那支部: 11,
  長野地方裁判所岩村田支部: 11,
  岐阜地方裁判所全支部: 11,
  岐阜地方裁判所多治見支部: 11,
  岐阜地方裁判所大垣支部: 11,
  岐阜地方裁判所高山支部: 11,
  岐阜地方裁判所八幡支部: 11,
  岐阜地方裁判所御嵩支部: 11,
  静岡地方裁判所全支部: 11,
  静岡地方裁判所浜松支部: 11,
  静岡地方裁判所沼津支部: 11,
  静岡地方裁判所富士支部: 11,
  静岡地方裁判所吉原支部: 11,
  静岡地方裁判所下田支部: 11,
  静岡地方裁判所掛川支部: 11,
  名古屋地方裁判所全支部: 11,
  名古屋地方裁判所一宮支部: 11,
  名古屋地方裁判所半田支部: 11,
  名古屋地方裁判所豊橋支部: 11,
  名古屋地方裁判所岡崎支部: 11,
  名古屋地方裁判所新城支部: 11,
  津地方裁判所全支部: 11,
  津地方裁判所伊勢支部: 11,
  津地方裁判所四日市支部: 11,
  津地方裁判所宇治山田支部: 11,
  津地方裁判所松阪支部: 11,
  津地方裁判所上野支部: 11,
  津地方裁判所熊野支部: 11,
  津地方裁判所安濃津支部: 11,
  津地方裁判所木本支部: 11,
  津地方裁判所伊賀支部: 11,
  大津地方裁判所全支部: 11,
  大津地方裁判所水口支部: 11,
  大津地方裁判所彦根支部: 11,
  大津地方裁判所長浜支部: 11,
  京都地方裁判所全支部: 11,
  京都地方裁判所園部支部: 11,
  京都地方裁判所宮津支部: 11,
  京都地方裁判所峰山支部: 11,
  京都地方裁判所舞鶴支部: 11,
  京都地方裁判所福知山支部: 11,
  神戸地方裁判所全支部: 11,
  神戸地方裁判所社支部: 11,
  神戸地方裁判所伊丹支部: 11,
  神戸地方裁判所姫路支部: 11,
  神戸地方裁判所尼崎支部: 11,
  神戸地方裁判所洲本支部: 11,
  神戸地方裁判所龍野支部: 11,
  神戸地方裁判所豊岡支部: 11,
  神戸地方裁判所明石支部: 11,
  神戸地方裁判所篠山支部: 11,
  神戸地方裁判所柏原支部: 11,
  奈良地方裁判所全支部: 11,
  奈良地方裁判所葛城支部: 11,
  奈良地方裁判所宇陀支部: 11,
  奈良地方裁判所五條支部: 11,
  和歌山地方裁判所全支部: 11,
  和歌山地方裁判所田辺支部: 11,
  和歌山地方裁判所御坊支部: 11,
  和歌山地方裁判所妙寺支部: 11,
  和歌山地方裁判所新宮支部: 11,
  鳥取地方裁判所全支部: 11,
  鳥取地方裁判所米子支部: 11,
  鳥取地方裁判所倉吉支部: 11,
  松江地方裁判所全支部: 11,
  松江地方裁判所今市支部: 11,
  松江地方裁判所木次支部: 11,
  松江地方裁判所出雲支部: 11,
  松江地方裁判所浜田支部: 11,
  松江地方裁判所益田支部: 11,
  松江地方裁判所西郷支部: 11,
  岡山地方裁判所全支部: 11,
  岡山地方裁判所津山支部: 11,
  岡山地方裁判所笠岡支部: 11,
  岡山地方裁判所倉敷支部: 11,
  岡山地方裁判所玉島支部: 11,
  岡山地方裁判所高梁支部: 11,
  岡山地方裁判所新見支部: 11,
  岡山地方裁判所勝山支部: 11,
  広島地方裁判所全支部: 11,
  広島地方裁判所福山支部: 11,
  広島地方裁判所尾道支部: 11,
  広島地方裁判所呉支部: 11,
  広島地方裁判所竹原支部: 11,
  広島地方裁判所三次支部: 11,
  広島地方裁判所庄原支部: 11,
  山口地方裁判所全支部: 11,
  山口地方裁判所下関支部: 11,
  山口地方裁判所岩国支部: 11,
  山口地方裁判所宇部支部: 11,
  山口地方裁判所船木支部: 11,
  山口地方裁判所徳山支部: 11,
  山口地方裁判所萩支部: 11,
  山口地方裁判所柳井支部: 11,
  山口地方裁判所周南支部: 11,
  徳島地方裁判所全支部: 11,
  徳島地方裁判所川島支部: 11,
  徳島地方裁判所富岡支部: 11,
  徳島地方裁判所阿南支部: 11,
  徳島地方裁判所脇町支部: 11,
  徳島地方裁判所美馬支部: 11,
  高松地方裁判所全支部: 11,
  高松地方裁判所丸亀支部: 11,
  高松地方裁判所観音寺支部: 11,
  松山地方裁判所全支部: 11,
  松山地方裁判所宇和島支部: 11,
  松山地方裁判所西条支部: 11,
  松山地方裁判所大洲支部: 11,
  松山地方裁判所八幡浜支部: 11,
  松山地方裁判所今治支部: 11,
  高知地方裁判所全支部: 11,
  高知地方裁判所須崎支部: 11,
  高知地方裁判所安芸支部: 11,
  高知地方裁判所中村支部: 11,
  福岡地方裁判所全支部: 11,
  福岡地方裁判所田川支部: 11,
  福岡地方裁判所行橋支部: 11,
  福岡地方裁判所八女支部: 11,
  福岡地方裁判所飯塚支部: 11,
  福岡地方裁判所小倉支部: 11,
  福岡地方裁判所大牟田支部: 11,
  福岡地方裁判所甘木支部: 11,
  福岡地方裁判所直方支部: 11,
  福岡地方裁判所久留米支部: 11,
  福岡地方裁判所吉井支部: 11,
  福岡地方裁判所柳川支部: 11,
  佐賀地方裁判所全支部: 11,
  佐賀地方裁判所唐津支部: 11,
  佐賀地方裁判所伊万里支部: 11,
  佐賀地方裁判所武雄支部: 11,
  長崎地方裁判所全支部: 11,
  長崎地方裁判所佐世保支部: 11,
  長崎地方裁判所島原支部: 11,
  長崎地方裁判所厳原支部: 11,
  長崎地方裁判所壱岐支部: 11,
  長崎地方裁判所大村支部: 11,
  長崎地方裁判所平戸支部: 11,
  長崎地方裁判所福江支部: 11,
  長崎地方裁判所飯田支部: 11,
  長崎地方裁判所武生水支部: 11,
  長崎地方裁判所五島支部: 11,
  熊本地方裁判所全支部: 11,
  熊本地方裁判所御船支部: 11,
  熊本地方裁判所天草支部: 11,
  熊本地方裁判所三角支部: 11,
  熊本地方裁判所玉名支部: 11,
  熊本地方裁判所山鹿支部: 11,
  熊本地方裁判所宮地支部: 11,
  熊本地方裁判所八代支部: 11,
  熊本地方裁判所人吉支部: 11,
  大分地方裁判所全支部: 11,
  大分地方裁判所佐伯支部: 11,
  大分地方裁判所杵築支部: 11,
  大分地方裁判所臼杵支部: 11,
  大分地方裁判所竹田支部: 11,
  大分地方裁判所中津支部: 11,
  大分地方裁判所豊後高田支部: 11,
  大分地方裁判所日田支部: 11,
  大分地方裁判所豆田支部: 11,
  宮崎地方裁判所全支部: 11,
  宮崎地方裁判所延岡支部: 11,
  宮崎地方裁判所日南支部: 11,
  宮崎地方裁判所都城支部: 11,
  宮崎地方裁判所高千穂支部: 11,
  鹿児島地方裁判所全支部: 11,
  鹿児島地方裁判所鹿屋支部: 11,
  鹿児島地方裁判所名瀬支部: 11,
  鹿児島地方裁判所加治木支部: 11,
  鹿児島地方裁判所知覧支部: 11,
  鹿児島地方裁判所川内支部: 11,
  那覇地方裁判所全支部: 11,
  那覇地方裁判所沖縄支部: 11,
  那覇地方裁判所名護支部: 11,
  那覇地方裁判所平良支部: 11,
  那覇地方裁判所石垣支部: 11,
  那覇地方裁判所コザ支部: 11,
  さいたま地方裁判所全支部: 11,
  さいたま地方裁判所越谷支部: 11,
  さいたま地方裁判所川越支部: 11,
  さいたま地方裁判所熊谷支部: 11,
  さいたま地方裁判所秩父支部: 11,
  関東地方院全支部: 11,
  関東地方院大連支部: 11,
  新潟地方裁判所十日町出張所: 12,
  東京家庭裁判所全支部: 13,
  東京家庭裁判所: 13,
  東京家庭裁判所八王子支部: 13,
  東京家庭裁判所八丈島出張所: 13,
  東京家庭裁判所伊豆大島出張所: 13,
  東京家庭裁判所立川支部: 13,
  東京簡易裁判所: 13,
  東京北簡易裁判所: 13,
  東京中野簡易裁判所: 13,
  東京区裁判所: 13,
  大阪家庭裁判所全支部: 14,
  大阪家庭裁判所: 14,
  大阪家庭裁判所堺支部: 14,
  大阪家庭裁判所岸和田支部: 14,
  大阪簡易裁判所: 14,
  大阪池田簡易裁判所: 14,
  東大阪簡易裁判所: 14,
  大阪区裁判所: 14,
  家庭裁判所: 15,
  旭川家庭裁判所: 15,
  旭川家庭裁判所深川出張所: 15,
  旭川家庭裁判所富良野出張所: 15,
  旭川家庭裁判所天塩出張所: 15,
  旭川家庭裁判所中頓別出張所: 15,
  釧路家庭裁判所: 15,
  釧路家庭裁判所広尾出張所: 15,
  釧路家庭裁判所標津出張所: 15,
  釧路家庭裁判所本別出張所: 15,
  釧路家庭裁判所遠軽出張所: 15,
  札幌家庭裁判所: 15,
  札幌家庭裁判所静内出張所: 15,
  札幌家庭裁判所夕張出張所: 15,
  札幌家庭裁判所苫小牧出張所: 15,
  函館家庭裁判所: 15,
  函館家庭裁判所松前出張所: 15,
  函館家庭裁判所八雲出張所: 15,
  函館家庭裁判所寿都出張所: 15,
  青森家庭裁判所: 15,
  青森家庭裁判所野辺地出張所: 15,
  青森家庭裁判所むつ出張所: 15,
  盛岡家庭裁判所: 15,
  盛岡家庭裁判所久慈出張所: 15,
  盛岡家庭裁判所大船渡出張所: 15,
  仙台家庭裁判所: 15,
  秋田家庭裁判所: 15,
  秋田家庭裁判所花輪出張所: 15,
  秋田家庭裁判所角館出張所: 15,
  秋田家庭裁判所鹿角出張所: 15,
  山形家庭裁判所: 15,
  山形家庭裁判所赤湯出張所: 15,
  山形家庭裁判所長井出張所: 15,
  福島家庭裁判所: 15,
  福島家庭裁判所喜多方出張所: 15,
  福島家庭裁判所棚倉出張所: 15,
  福島家庭裁判所田島出張所: 15,
  水戸家庭裁判所: 15,
  宇都宮家庭裁判所: 15,
  宇都宮家庭裁判所烏山出張所: 15,
  前橋家庭裁判所: 15,
  前橋家庭裁判所中之条出張所: 15,
  浦和家庭裁判所: 15,
  浦和家庭裁判所飯能出張所: 15,
  浦和家庭裁判所久喜出張所: 15,
  千葉家庭裁判所: 15,
  千葉家庭裁判所市川出張所: 15,
  横浜家庭裁判所: 15,
  横浜家庭裁判所相模原出張所: 15,
  新潟家庭裁判所: 15,
  新潟家庭裁判所十日町出張所: 15,
  新潟家庭裁判所村上出張所: 15,
  新潟家庭裁判所柏崎出張所: 15,
  新潟家庭裁判所六日町出張所: 15,
  新潟家庭裁判所糸魚川出張所: 15,
  富山家庭裁判所: 15,
  富山家庭裁判所砺波出張所: 15,
  金沢家庭裁判所: 15,
  金沢家庭裁判所小浜出張所: 15,
  金沢家庭裁判所珠洲出張所: 15,
  福井家庭裁判所: 15,
  甲府家庭裁判所: 15,
  長野家庭裁判所: 15,
  長野家庭裁判所飯山出張所: 15,
  長野家庭裁判所木曾福島出張所: 15,
  長野家庭裁判所大町出張所: 15,
  岐阜家庭裁判所: 15,
  岐阜家庭裁判所八幡出張所: 15,
  岐阜家庭裁判所中津川出張所: 15,
  静岡家庭裁判所: 15,
  静岡家庭裁判所熱海出張所: 15,
  静岡家庭裁判所島田出張所: 15,
  名古屋家庭裁判所: 15,
  津家庭裁判所: 15,
  津家庭裁判所尾鷲出張所: 15,
  大津家庭裁判所: 15,
  大津家庭裁判所今津出張所: 15,
  大津家庭裁判所高島出張所: 15,
  京都家庭裁判所: 15,
  神戸家庭裁判所: 15,
  神戸家庭裁判所浜坂出張所: 15,
  奈良家庭裁判所: 15,
  奈良家庭裁判所吉野出張所: 15,
  和歌山家庭裁判所: 15,
  和歌山家庭裁判所妙寺出張所: 15,
  鳥取家庭裁判所: 15,
  松江家庭裁判所: 15,
  松江家庭裁判所木次出張所: 15,
  松江家庭裁判所川本出張所: 15,
  岡山家庭裁判所: 15,
  岡山家庭裁判所玉野出張所: 15,
  岡山家庭裁判所備前出張所: 15,
  岡山家庭裁判所児島出張所: 15,
  岡山家庭裁判所玉島出張所: 15,
  岡山家庭裁判所笠岡出張所: 15,
  広島家庭裁判所: 15,
  山口家庭裁判所: 15,
  山口家庭裁判所船木出張所: 15,
  山口家庭裁判所柳井出張所: 15,
  徳島家庭裁判所: 15,
  徳島家庭裁判所牟岐出張所: 15,
  徳島家庭裁判所池田出張所: 15,
  高松家庭裁判所: 15,
  高松家庭裁判所土庄出張所: 15,
  松山家庭裁判所: 15,
  松山家庭裁判所城辺出張所: 15,
  高知家庭裁判所: 15,
  福岡家庭裁判所: 15,
  福岡家庭裁判所甘木出張所: 15,
  佐賀家庭裁判所: 15,
  佐賀家庭裁判所鹿島出張所: 15,
  長崎家庭裁判所: 15,
  長崎家庭裁判所上県出張所: 15,
  長崎家庭裁判所諫早出張所: 15,
  長崎家庭裁判所有川出張所: 15,
  熊本家庭裁判所: 15,
  熊本家庭裁判所高森出張所: 15,
  熊本家庭裁判所水俣出張所: 15,
  熊本家庭裁判所牛深出張所: 15,
  大分家庭裁判所: 15,
  大分家庭裁判所国東出張所: 15,
  大分家庭裁判所豊後高田出張所: 15,
  宮崎家庭裁判所: 15,
  宮崎家庭裁判所日向出張所: 15,
  宮崎家庭裁判所高千穂出張所: 15,
  鹿児島家庭裁判所: 15,
  鹿児島家庭裁判所種子島出張所: 15,
  鹿児島家庭裁判所屋久島出張所: 15,
  鹿児島家庭裁判所指宿出張所: 15,
  鹿児島家庭裁判所大口出張所: 15,
  鹿児島家庭裁判所徳之島出張所: 15,
  那覇家庭裁判所: 15,
  さいたま家庭裁判所: 15,
  さいたま家庭裁判所飯能出張所: 15,
  さいたま家庭裁判所久喜出張所: 15,
  行政裁判所: 15,
  旭川区裁判所: 15,
  名寄区裁判所: 15,
  増毛区裁判所: 15,
  稚内区裁判所: 15,
  釧路区裁判所: 15,
  帯広区裁判所: 15,
  網走区裁判所: 15,
  野付牛区裁判所: 15,
  根室区裁判所: 15,
  札幌区裁判所: 15,
  岩見沢区裁判所: 15,
  小樽区裁判所: 15,
  浦河区裁判所: 15,
  岩内区裁判所: 15,
  室蘭区裁判所: 15,
  函館区裁判所: 15,
  江差区裁判所: 15,
  寿都区裁判所: 15,
  青森区裁判所: 15,
  五所川原区裁判所: 15,
  弘前区裁判所: 15,
  鯵ケ沢区裁判所: 15,
  八戸区裁判所: 15,
  盛岡区裁判所: 15,
  花巻区裁判所: 15,
  二戸区裁判所: 15,
  遠野区裁判所: 15,
  宮古区裁判所: 15,
  一関区裁判所: 15,
  水沢区裁判所: 15,
  仙台区裁判所: 15,
  大河原区裁判所: 15,
  古川区裁判所: 15,
  石巻区裁判所: 15,
  登米区裁判所: 15,
  気仙沼区裁判所: 15,
  秋田区裁判所: 15,
  能代区裁判所: 15,
  本荘区裁判所: 15,
  大館区裁判所: 15,
  横手区裁判所: 15,
  湯沢区裁判所: 15,
  大曲区裁判所: 15,
  山形区裁判所: 15,
  新庄区裁判所: 15,
  米沢区裁判所: 15,
  鶴岡区裁判所: 15,
  酒田区裁判所: 15,
  福島区裁判所: 15,
  相馬区裁判所: 15,
  郡山区裁判所: 15,
  白河区裁判所: 15,
  若松区裁判所: 15,
  平区裁判所: 15,
  水戸区裁判所: 15,
  太田区裁判所: 15,
  土浦区裁判所: 15,
  龍ケ崎区裁判所: 15,
  麻生区裁判所: 15,
  下妻区裁判所: 15,
  宇都宮区裁判所: 15,
  芳賀区裁判所: 15,
  栃木区裁判所: 15,
  大田原区裁判所: 15,
  足利区裁判所: 15,
  前橋区裁判所: 15,
  沼田区裁判所: 15,
  新田区裁判所: 15,
  高崎区裁判所: 15,
  中之条区裁判所: 15,
  北甘楽区裁判所: 15,
  浦和区裁判所: 15,
  越ケ谷区裁判所: 15,
  川越区裁判所: 15,
  熊谷区裁判所: 15,
  秩父区裁判所: 15,
  千葉区裁判所: 15,
  佐倉区裁判所: 15,
  一宮本郷区裁判所: 15,
  松戸区裁判所: 15,
  木更津区裁判所: 15,
  北條区裁判所: 15,
  八日市場区裁判所: 15,
  佐原区裁判所: 15,
  八王子区裁判所: 15,
  横浜区裁判所: 15,
  横須賀区裁判所: 15,
  小田原区裁判所: 15,
  新潟区裁判所: 15,
  三条区裁判所: 15,
  新発田区裁判所: 15,
  村上区裁判所: 15,
  長岡区裁判所: 15,
  柏崎区裁判所: 15,
  六日町区裁判所: 15,
  高田区裁判所: 15,
  糸魚川区裁判所: 15,
  相川区裁判所: 15,
  富山区裁判所: 15,
  魚津区裁判所: 15,
  高岡区裁判所: 15,
  出町区裁判所: 15,
  金沢区裁判所: 15,
  小松区裁判所: 15,
  七尾区裁判所: 15,
  輪島区裁判所: 15,
  福井区裁判所: 15,
  武生区裁判所: 15,
  大野区裁判所: 15,
  敦賀区裁判所: 15,
  小浜区裁判所: 15,
  甲府区裁判所: 15,
  鰍沢区裁判所: 15,
  谷村区裁判所: 15,
  長野区裁判所: 15,
  飯山区裁判所: 15,
  上諏訪区裁判所: 15,
  岩村田区裁判所: 15,
  松本区裁判所: 15,
  木曾区裁判所: 15,
  大町区裁判所: 15,
  上田区裁判所: 15,
  飯田区裁判所: 15,
  伊那区裁判所: 15,
  岐阜区裁判所: 15,
  八幡区裁判所: 15,
  大垣区裁判所: 15,
  御嵩区裁判所: 15,
  高山区裁判所: 15,
  静岡区裁判所: 15,
  沼津区裁判所: 15,
  吉原区裁判所: 15,
  下田区裁判所: 15,
  浜松区裁判所: 15,
  掛川区裁判所: 15,
  名古屋区裁判所: 15,
  一宮区裁判所: 15,
  半田区裁判所: 15,
  岡崎区裁判所: 15,
  豊橋区裁判所: 15,
  新城区裁判所: 15,
  津区裁判所: 15,
  安濃津区裁判所: 15,
  四日市区裁判所: 15,
  上野区裁判所: 15,
  松阪区裁判所: 15,
  山田区裁判所: 15,
  大本区裁判所: 15,
  大津区裁判所: 15,
  水口区裁判所: 15,
  彦根区裁判所: 15,
  長浜区裁判所: 15,
  京都区裁判所: 15,
  園部区裁判所: 15,
  宮津区裁判所: 15,
  峰山区裁判所: 15,
  舞鶴区裁判所: 15,
  福知山区裁判所: 15,
  堺区裁判所: 15,
  岸和田区裁判所: 15,
  神戸区裁判所: 15,
  明石区裁判所: 15,
  伊丹区裁判所: 15,
  篠山区裁判所: 15,
  柏原区裁判所: 15,
  姫路区裁判所: 15,
  社区裁判所: 15,
  豊岡区裁判所: 15,
  龍野区裁判所: 15,
  洲本区裁判所: 15,
  奈良区裁判所: 15,
  葛城区裁判所: 15,
  宇陀区裁判所: 15,
  五條区裁判所: 15,
  和歌山区裁判所: 15,
  妙寺区裁判所: 15,
  田辺区裁判所: 15,
  御坊区裁判所: 15,
  新宮区裁判所: 15,
  鳥取区裁判所: 15,
  倉吉区裁判所: 15,
  米子区裁判所: 15,
  松江区裁判所: 15,
  木次区裁判所: 15,
  今市区裁判所: 15,
  浜田区裁判所: 15,
  益田区裁判所: 15,
  大森区裁判所: 15,
  西郷区裁判所: 15,
  岡山区裁判所: 15,
  玉島区裁判所: 15,
  笠岡区裁判所: 15,
  高梁区裁判所: 15,
  新見区裁判所: 15,
  津山区裁判所: 15,
  勝山区裁判所: 15,
  広島区裁判所: 15,
  呉区裁判所: 15,
  尾道区裁判所: 15,
  福山区裁判所: 15,
  三次区裁判所: 15,
  庄原区裁判所: 15,
  竹原区裁判所: 15,
  山口区裁判所: 15,
  萩区裁判所: 15,
  徳山区裁判所: 15,
  岩国区裁判所: 15,
  下関区裁判所: 15,
  船木区裁判所: 15,
  徳島区裁判所: 15,
  富岡区裁判所: 15,
  脇町区裁判所: 15,
  川島区裁判所: 15,
  高松区裁判所: 15,
  丸亀区裁判所: 15,
  観音寺区裁判所: 15,
  松山区裁判所: 15,
  大洲区裁判所: 15,
  八幡浜区裁判所: 15,
  西条区裁判所: 15,
  今治区裁判所: 15,
  宇和島区裁判所: 15,
  高知区裁判所: 15,
  須崎区裁判所: 15,
  安芸区裁判所: 15,
  中村区裁判所: 15,
  福岡区裁判所: 15,
  甘木区裁判所: 15,
  飯塚区裁判所: 15,
  直方区裁判所: 15,
  久留米区裁判所: 15,
  吉井区裁判所: 15,
  柳川区裁判所: 15,
  八女区裁判所: 15,
  小倉区裁判所: 15,
  行橋区裁判所: 15,
  田川区裁判所: 15,
  佐賀区裁判所: 15,
  伊万里区裁判所: 15,
  武雄区裁判所: 15,
  唐津区裁判所: 15,
  長崎区裁判所: 15,
  大村区裁判所: 15,
  島原区裁判所: 15,
  佐世保区裁判所: 15,
  平戸区裁判所: 15,
  武生水区裁判所: 15,
  福江区裁判所: 15,
  厳原区裁判所: 15,
  熊本区裁判所: 15,
  三角区裁判所: 15,
  高瀬区裁判所: 15,
  御船区裁判所: 15,
  山鹿区裁判所: 15,
  宮地区裁判所: 15,
  八代区裁判所: 15,
  人吉区裁判所: 15,
  天草区裁判所: 15,
  大分区裁判所: 15,
  杵築区裁判所: 15,
  臼杵区裁判所: 15,
  佐伯区裁判所: 15,
  竹田区裁判所: 15,
  中津区裁判所: 15,
  玉津区裁判所: 15,
  日田区裁判所: 15,
  宮崎区裁判所: 15,
  飫肥区裁判所: 15,
  延岡区裁判所: 15,
  都城区裁判所: 15,
  高千穂区裁判所: 15,
  鹿児島区裁判所: 15,
  加治木区裁判所: 15,
  知覧区裁判所: 15,
  川内区裁判所: 15,
  鹿屋区裁判所: 15,
  大島区裁判所: 15,
  那覇区裁判所: 15,
  平良区裁判所: 15,
  豊原区裁判所: 15,
  真岡区裁判所: 15,
  知取区裁判所: 15,
  裁判官弾劾裁判所: 15,
  旭川家庭裁判所全支部: 16,
  旭川家庭裁判所名寄支部: 16,
  旭川家庭裁判所紋別支部: 16,
  旭川家庭裁判所留萌支部: 16,
  旭川家庭裁判所稚内支部: 16,
  釧路家庭裁判所全支部: 16,
  釧路家庭裁判所帯広支部: 16,
  釧路家庭裁判所網走支部: 16,
  釧路家庭裁判所北見支部: 16,
  釧路家庭裁判所根室支部: 16,
  札幌家庭裁判所全支部: 16,
  札幌家庭裁判所岩見沢支部: 16,
  札幌家庭裁判所滝川支部: 16,
  札幌家庭裁判所室蘭支部: 16,
  札幌家庭裁判所浦河支部: 16,
  札幌家庭裁判所小樽支部: 16,
  札幌家庭裁判所岩内支部: 16,
  札幌家庭裁判所苫小牧支部: 16,
  函館家庭裁判所全支部: 16,
  函館家庭裁判所江差支部: 16,
  函館家庭裁判所寿都支部: 16,
  青森家庭裁判所全支部: 16,
  青森家庭裁判所五所川原支部: 16,
  青森家庭裁判所弘前支部: 16,
  青森家庭裁判所鰺ケ沢支部: 16,
  青森家庭裁判所八戸支部: 16,
  青森家庭裁判所十和田支部: 16,
  盛岡家庭裁判所全支部: 16,
  盛岡家庭裁判所花巻支部: 16,
  盛岡家庭裁判所二戸支部: 16,
  盛岡家庭裁判所遠野支部: 16,
  盛岡家庭裁判所宮古支部: 16,
  盛岡家庭裁判所一関支部: 16,
  盛岡家庭裁判所水沢支部: 16,
  仙台家庭裁判所全支部: 16,
  仙台家庭裁判所大河原支部: 16,
  仙台家庭裁判所古川支部: 16,
  仙台家庭裁判所石巻支部: 16,
  仙台家庭裁判所登米支部: 16,
  仙台家庭裁判所気仙沼支部: 16,
  秋田家庭裁判所全支部: 16,
  秋田家庭裁判所能代支部: 16,
  秋田家庭裁判所本荘支部: 16,
  秋田家庭裁判所大館支部: 16,
  秋田家庭裁判所横手支部: 16,
  秋田家庭裁判所湯沢支部: 16,
  秋田家庭裁判所大曲支部: 16,
  山形家庭裁判所全支部: 16,
  山形家庭裁判所新庄支部: 16,
  山形家庭裁判所米沢支部: 16,
  山形家庭裁判所鶴岡支部: 16,
  山形家庭裁判所酒田支部: 16,
  福島家庭裁判所全支部: 16,
  福島家庭裁判所相馬支部: 16,
  福島家庭裁判所郡山支部: 16,
  福島家庭裁判所白河支部: 16,
  福島家庭裁判所会津若松支部: 16,
  福島家庭裁判所いわき支部: 16,
  福島家庭裁判所平支部: 16,
  水戸家庭裁判所全支部: 16,
  水戸家庭裁判所日立支部: 16,
  水戸家庭裁判所常陸太田支部: 16,
  水戸家庭裁判所土浦支部: 16,
  水戸家庭裁判所龍ケ崎支部: 16,
  水戸家庭裁判所麻生支部: 16,
  水戸家庭裁判所下妻支部: 16,
  宇都宮家庭裁判所全支部: 16,
  宇都宮家庭裁判所真岡支部: 16,
  宇都宮家庭裁判所大田原支部: 16,
  宇都宮家庭裁判所栃木支部: 16,
  宇都宮家庭裁判所足利支部: 16,
  前橋家庭裁判所全支部: 16,
  前橋家庭裁判所高崎支部: 16,
  前橋家庭裁判所桐生支部: 16,
  前橋家庭裁判所太田支部: 16,
  前橋家庭裁判所沼田支部: 16,
  前橋家庭裁判所富岡支部: 16,
  前橋家庭裁判所中之条支部: 16,
  浦和家庭裁判所全支部: 16,
  浦和家庭裁判所越谷支部: 16,
  浦和家庭裁判所川越支部: 16,
  浦和家庭裁判所熊谷支部: 16,
  浦和家庭裁判所秩父支部: 16,
  千葉家庭裁判所全支部: 16,
  千葉家庭裁判所佐倉支部: 16,
  千葉家庭裁判所一宮支部: 16,
  千葉家庭裁判所松戸支部: 16,
  千葉家庭裁判所木更津支部: 16,
  千葉家庭裁判所館山支部: 16,
  千葉家庭裁判所八日市場支部: 16,
  千葉家庭裁判所佐原支部: 16,
  横浜家庭裁判所全支部: 16,
  横浜家庭裁判所川崎支部: 16,
  横浜家庭裁判所横須賀支部: 16,
  横浜家庭裁判所小田原支部: 16,
  横浜家庭裁判所相模原支部: 16,
  新潟家庭裁判所全支部: 16,
  新潟家庭裁判所三条支部: 16,
  新潟家庭裁判所新発田支部: 16,
  新潟家庭裁判所村上支部: 16,
  新潟家庭裁判所長岡支部: 16,
  新潟家庭裁判所柏崎支部: 16,
  新潟家庭裁判所六日町支部: 16,
  新潟家庭裁判所高田支部: 16,
  新潟家庭裁判所糸魚川支部: 16,
  新潟家庭裁判所佐渡支部: 16,
  富山家庭裁判所全支部: 16,
  富山家庭裁判所魚津支部: 16,
  富山家庭裁判所高岡支部: 16,
  富山家庭裁判所砺波支部: 16,
  金沢家庭裁判所全支部: 16,
  金沢家庭裁判所小松支部: 16,
  金沢家庭裁判所七尾支部: 16,
  金沢家庭裁判所輪島支部: 16,
  福井家庭裁判所全支部: 16,
  福井家庭裁判所武生支部: 16,
  福井家庭裁判所大野支部: 16,
  福井家庭裁判所敦賀支部: 16,
  福井家庭裁判所小浜支部: 16,
  甲府家庭裁判所全支部: 16,
  甲府家庭裁判所鰍沢支部: 16,
  甲府家庭裁判所都留支部: 16,
  長野家庭裁判所全支部: 16,
  長野家庭裁判所飯山支部: 16,
  長野家庭裁判所上田支部: 16,
  長野家庭裁判所佐久支部: 16,
  長野家庭裁判所松本支部: 16,
  長野家庭裁判所木曾支部: 16,
  長野家庭裁判所大町支部: 16,
  長野家庭裁判所諏訪支部: 16,
  長野家庭裁判所飯田支部: 16,
  長野家庭裁判所伊那支部: 16,
  岐阜家庭裁判所全支部: 16,
  岐阜家庭裁判所大垣支部: 16,
  岐阜家庭裁判所高山支部: 16,
  岐阜家庭裁判所多治見支部: 16,
  岐阜家庭裁判所八幡支部: 16,
  岐阜家庭裁判所御嵩支部: 16,
  静岡家庭裁判所全支部: 16,
  静岡家庭裁判所沼津支部: 16,
  静岡家庭裁判所富士支部: 16,
  静岡家庭裁判所下田支部: 16,
  静岡家庭裁判所浜松支部: 16,
  静岡家庭裁判所掛川支部: 16,
  名古屋家庭裁判所全支部: 16,
  名古屋家庭裁判所一宮支部: 16,
  名古屋家庭裁判所半田支部: 16,
  名古屋家庭裁判所岡崎支部: 16,
  名古屋家庭裁判所豊橋支部: 16,
  名古屋家庭裁判所新城支部: 16,
  津家庭裁判所全支部: 16,
  津家庭裁判所四日市支部: 16,
  津家庭裁判所松阪支部: 16,
  津家庭裁判所上野支部: 16,
  津家庭裁判所伊勢支部: 16,
  津家庭裁判所熊野支部: 16,
  大津家庭裁判所全支部: 16,
  大津家庭裁判所水口支部: 16,
  大津家庭裁判所彦根支部: 16,
  大津家庭裁判所長浜支部: 16,
  京都家庭裁判所全支部: 16,
  京都家庭裁判所園部支部: 16,
  京都家庭裁判所宮津支部: 16,
  京都家庭裁判所峰山支部: 16,
  京都家庭裁判所舞鶴支部: 16,
  京都家庭裁判所福知山支部: 16,
  神戸家庭裁判所全支部: 16,
  神戸家庭裁判所伊丹支部: 16,
  神戸家庭裁判所尼崎支部: 16,
  神戸家庭裁判所明石支部: 16,
  神戸家庭裁判所篠山支部: 16,
  神戸家庭裁判所柏原支部: 16,
  神戸家庭裁判所姫路支部: 16,
  神戸家庭裁判所社支部: 16,
  神戸家庭裁判所龍野支部: 16,
  神戸家庭裁判所豊岡支部: 16,
  神戸家庭裁判所洲本支部: 16,
  奈良家庭裁判所全支部: 16,
  奈良家庭裁判所葛城支部: 16,
  奈良家庭裁判所宇陀支部: 16,
  奈良家庭裁判所五條支部: 16,
  和歌山家庭裁判所全支部: 16,
  和歌山家庭裁判所妙寺支部: 16,
  和歌山家庭裁判所田辺支部: 16,
  和歌山家庭裁判所御坊支部: 16,
  和歌山家庭裁判所新宮支部: 16,
  鳥取家庭裁判所全支部: 16,
  鳥取家庭裁判所倉吉支部: 16,
  鳥取家庭裁判所米子支部: 16,
  松江家庭裁判所全支部: 16,
  松江家庭裁判所木次支部: 16,
  松江家庭裁判所出雲支部: 16,
  松江家庭裁判所浜田支部: 16,
  松江家庭裁判所益田支部: 16,
  松江家庭裁判所西郷支部: 16,
  岡山家庭裁判所全支部: 16,
  岡山家庭裁判所倉敷支部: 16,
  岡山家庭裁判所笠岡支部: 16,
  岡山家庭裁判所高梁支部: 16,
  岡山家庭裁判所新見支部: 16,
  岡山家庭裁判所津山支部: 16,
  岡山家庭裁判所勝山支部: 16,
  広島家庭裁判所全支部: 16,
  広島家庭裁判所呉支部: 16,
  広島家庭裁判所竹原支部: 16,
  広島家庭裁判所尾道支部: 16,
  広島家庭裁判所福山支部: 16,
  広島家庭裁判所三次支部: 16,
  広島家庭裁判所庄原支部: 16,
  山口家庭裁判所全支部: 16,
  山口家庭裁判所徳山支部: 16,
  山口家庭裁判所萩支部: 16,
  山口家庭裁判所岩国支部: 16,
  山口家庭裁判所柳井支部: 16,
  山口家庭裁判所下関支部: 16,
  山口家庭裁判所宇部支部: 16,
  山口家庭裁判所船木支部: 16,
  山口家庭裁判所周南支部: 16,
  徳島家庭裁判所全支部: 16,
  徳島家庭裁判所阿南支部: 16,
  徳島家庭裁判所脇町支部: 16,
  徳島家庭裁判所川島支部: 16,
  高松家庭裁判所全支部: 16,
  高松家庭裁判所丸亀支部: 16,
  高松家庭裁判所観音寺支部: 16,
  松山家庭裁判所全支部: 16,
  松山家庭裁判所大洲支部: 16,
  松山家庭裁判所八幡浜支部: 16,
  松山家庭裁判所西条支部: 16,
  松山家庭裁判所今治支部: 16,
  松山家庭裁判所宇和島支部: 16,
  高知家庭裁判所全支部: 16,
  高知家庭裁判所須崎支部: 16,
  高知家庭裁判所安芸支部: 16,
  高知家庭裁判所中村支部: 16,
  福岡家庭裁判所全支部: 16,
  福岡家庭裁判所甘木支部: 16,
  福岡家庭裁判所小倉支部: 16,
  福岡家庭裁判所飯塚支部: 16,
  福岡家庭裁判所直方支部: 16,
  福岡家庭裁判所久留米支部: 16,
  福岡家庭裁判所吉井支部: 16,
  福岡家庭裁判所柳川支部: 16,
  福岡家庭裁判所大牟田支部: 16,
  福岡家庭裁判所八女支部: 16,
  福岡家庭裁判所行橋支部: 16,
  福岡家庭裁判所田川支部: 16,
  佐賀家庭裁判所全支部: 16,
  佐賀家庭裁判所武雄支部: 16,
  佐賀家庭裁判所伊万里支部: 16,
  佐賀家庭裁判所唐津支部: 16,
  長崎家庭裁判所全支部: 16,
  長崎家庭裁判所大村支部: 16,
  長崎家庭裁判所島原支部: 16,
  長崎家庭裁判所佐世保支部: 16,
  長崎家庭裁判所平戸支部: 16,
  長崎家庭裁判所壱岐支部: 16,
  長崎家庭裁判所福江支部: 16,
  長崎家庭裁判所厳原支部: 16,
  長崎家庭裁判所武生水支部: 16,
  熊本家庭裁判所全支部: 16,
  熊本家庭裁判所三角支部: 16,
  熊本家庭裁判所玉名支部: 16,
  熊本家庭裁判所御船支部: 16,
  熊本家庭裁判所山鹿支部: 16,
  熊本家庭裁判所宮地支部: 16,
  熊本家庭裁判所八代支部: 16,
  熊本家庭裁判所人吉支部: 16,
  熊本家庭裁判所天草支部: 16,
  大分家庭裁判所全支部: 16,
  大分家庭裁判所杵築支部: 16,
  大分家庭裁判所臼杵支部: 16,
  大分家庭裁判所佐伯支部: 16,
  大分家庭裁判所竹田支部: 16,
  大分家庭裁判所中津支部: 16,
  大分家庭裁判所豊後高田支部: 16,
  大分家庭裁判所日田支部: 16,
  宮崎家庭裁判所全支部: 16,
  宮崎家庭裁判所日南支部: 16,
  宮崎家庭裁判所都城支部: 16,
  宮崎家庭裁判所延岡支部: 16,
  宮崎家庭裁判所高千穂支部: 16,
  鹿児島家庭裁判所全支部: 16,
  鹿児島家庭裁判所名瀬支部: 16,
  鹿児島家庭裁判所加治木支部: 16,
  鹿児島家庭裁判所知覧支部: 16,
  鹿児島家庭裁判所川内支部: 16,
  鹿児島家庭裁判所鹿屋支部: 16,
  那覇家庭裁判所全支部: 16,
  那覇家庭裁判所沖縄支部: 16,
  那覇家庭裁判所名護支部: 16,
  那覇家庭裁判所平良支部: 16,
  那覇家庭裁判所石垣支部: 16,
  那覇家庭裁判所コザ支部: 16,
  さいたま家庭裁判所全支部: 16,
  さいたま家庭裁判所越谷支部: 16,
  さいたま家庭裁判所川越支部: 16,
  さいたま家庭裁判所熊谷支部: 16,
  さいたま家庭裁判所秩父支部: 16,
  簡易裁判所: 17,
  札幌簡易裁判所: 17,
  函館簡易裁判所: 17,
  旭川簡易裁判所: 17,
  岩見沢簡易裁判所: 17,
  斜里簡易裁判所: 17,
  士別簡易裁判所: 17,
  滝川簡易裁判所: 17,
  北見簡易裁判所: 17,
  網走簡易裁判所: 17,
  名寄簡易裁判所: 17,
  帯広簡易裁判所: 17,
  富良野簡易裁判所: 17,
  小樽簡易裁判所: 17,
  紋別簡易裁判所: 17,
  室蘭簡易裁判所: 17,
  遠軽簡易裁判所: 17,
  浦河簡易裁判所: 17,
  静内簡易裁判所: 17,
  夕張簡易裁判所: 17,
  伊達簡易裁判所: 17,
  苫小牧簡易裁判所: 17,
  岩内簡易裁判所: 17,
  松前簡易裁判所: 17,
  八雲簡易裁判所: 17,
  寿都簡易裁判所: 17,
  江差簡易裁判所: 17,
  深川簡易裁判所: 17,
  留萌簡易裁判所: 17,
  稚内簡易裁判所: 17,
  天塩簡易裁判所: 17,
  中頓別簡易裁判所: 17,
  標津簡易裁判所: 17,
  本別簡易裁判所: 17,
  釧路簡易裁判所: 17,
  根室簡易裁判所: 17,
  倶知安簡易裁判所: 17,
  青森簡易裁判所: 17,
  鰺ケ沢簡易裁判所: 17,
  五所川原簡易裁判所: 17,
  弘前簡易裁判所: 17,
  八戸簡易裁判所: 17,
  三沢簡易裁判所: 17,
  むつ簡易裁判所: 17,
  野辺地簡易裁判所: 17,
  十和田簡易裁判所: 17,
  盛岡簡易裁判所: 17,
  久慈簡易裁判所: 17,
  花巻簡易裁判所: 17,
  一関簡易裁判所: 17,
  二戸簡易裁判所: 17,
  遠野簡易裁判所: 17,
  釜石簡易裁判所: 17,
  宮古簡易裁判所: 17,
  大船渡簡易裁判所: 17,
  水沢簡易裁判所: 17,
  仙台簡易裁判所: 17,
  石巻簡易裁判所: 17,
  登米簡易裁判所: 17,
  築館簡易裁判所: 17,
  大河原簡易裁判所: 17,
  古川簡易裁判所: 17,
  気仙沼簡易裁判所: 17,
  秋田簡易裁判所: 17,
  能代簡易裁判所: 17,
  大館簡易裁判所: 17,
  男鹿簡易裁判所: 17,
  本荘簡易裁判所: 17,
  鹿角簡易裁判所: 17,
  大曲簡易裁判所: 17,
  角館簡易裁判所: 17,
  横手簡易裁判所: 17,
  湯沢簡易裁判所: 17,
  船川港簡易裁判所: 17,
  山形簡易裁判所: 17,
  鶴岡簡易裁判所: 17,
  朝日簡易裁判所: 17,
  酒田簡易裁判所: 17,
  新庄簡易裁判所: 17,
  赤湯簡易裁判所: 17,
  長井簡易裁判所: 17,
  楯岡簡易裁判所: 17,
  米沢簡易裁判所: 17,
  福島簡易裁判所: 17,
  若松簡易裁判所: 17,
  いわき簡易裁判所: 17,
  白河簡易裁判所: 17,
  平簡易裁判所: 17,
  相馬簡易裁判所: 17,
  郡山簡易裁判所: 17,
  棚倉簡易裁判所: 17,
  会津若松簡易裁判所: 17,
  田島簡易裁判所: 17,
  福島富岡簡易裁判所: 17,
  二本松簡易裁判所: 17,
  水戸簡易裁判所: 17,
  石岡簡易裁判所: 17,
  土浦簡易裁判所: 17,
  笠間簡易裁判所: 17,
  常陸太田簡易裁判所: 17,
  日立簡易裁判所: 17,
  龍ケ崎簡易裁判所: 17,
  取手簡易裁判所: 17,
  麻生簡易裁判所: 17,
  下妻簡易裁判所: 17,
  下館簡易裁判所: 17,
  古河簡易裁判所: 17,
  茨城太田簡易裁判所: 17,
  宇都宮簡易裁判所: 17,
  真岡簡易裁判所: 17,
  大田原簡易裁判所: 17,
  佐野簡易裁判所: 17,
  足利簡易裁判所: 17,
  栃木簡易裁判所: 17,
  小山簡易裁判所: 17,
  矢板簡易裁判所: 17,
  烏山簡易裁判所: 17,
  前橋簡易裁判所: 17,
  藤岡簡易裁判所: 17,
  中之条簡易裁判所: 17,
  桐生簡易裁判所: 17,
  太田簡易裁判所: 17,
  高崎簡易裁判所: 17,
  伊勢崎簡易裁判所: 17,
  沼田簡易裁判所: 17,
  館林簡易裁判所: 17,
  群馬富岡簡易裁判所: 17,
  浦和簡易裁判所: 17,
  越谷簡易裁判所: 17,
  川越簡易裁判所: 17,
  秩父簡易裁判所: 17,
  熊谷簡易裁判所: 17,
  川島簡易裁判所埼玉: 17,
  川口簡易裁判所: 17,
  大宮簡易裁判所: 17,
  久喜簡易裁判所: 17,
  飯能簡易裁判所: 17,
  本庄簡易裁判所: 17,
  所沢簡易裁判所: 17,
  さいたま簡易裁判所: 17,
  千葉簡易裁判所: 17,
  銚子簡易裁判所: 17,
  東金簡易裁判所: 17,
  佐倉簡易裁判所: 17,
  松戸簡易裁判所: 17,
  木更津簡易裁判所: 17,
  市川簡易裁判所: 17,
  館山簡易裁判所: 17,
  八日市場簡易裁判所: 17,
  佐原簡易裁判所: 17,
  大原簡易裁判所: 17,
  千葉一宮簡易裁判所: 17,
  中野簡易裁判所: 17,
  新宿簡易裁判所: 17,
  渋谷簡易裁判所: 17,
  大森簡易裁判所: 17,
  墨田簡易裁判所: 17,
  立川簡易裁判所: 17,
  足立簡易裁判所: 17,
  豊島簡易裁判所: 17,
  江戸川簡易裁判所: 17,
  八王子簡易裁判所: 17,
  武蔵野簡易裁判所: 17,
  台東簡易裁判所: 17,
  小笠原簡易裁判所: 17,
  葛飾簡易裁判所: 17,
  八丈島簡易裁判所: 17,
  伊豆大島簡易裁判所: 17,
  新島簡易裁判所: 17,
  青梅簡易裁判所: 17,
  町田簡易裁判所: 17,
  横浜簡易裁判所: 17,
  鎌倉簡易裁判所: 17,
  神奈川簡易裁判所: 17,
  横須賀簡易裁判所: 17,
  川崎簡易裁判所: 17,
  保土ケ谷簡易裁判所: 17,
  藤沢簡易裁判所: 17,
  相模原簡易裁判所: 17,
  小田原簡易裁判所: 17,
  平塚簡易裁判所: 17,
  厚木簡易裁判所: 17,
  横浜南簡易裁判所: 17,
  津久井簡易裁判所: 17,
  横浜西簡易裁判所: 17,
  新潟簡易裁判所: 17,
  相川簡易裁判所: 17,
  三条簡易裁判所: 17,
  湯沢簡易裁判所新潟: 17,
  巻簡易裁判所: 17,
  六日町簡易裁判所: 17,
  長岡簡易裁判所: 17,
  柏崎簡易裁判所: 17,
  十日町簡易裁判所: 17,
  糸魚川簡易裁判所: 17,
  新津簡易裁判所: 17,
  新発田簡易裁判所: 17,
  村上簡易裁判所: 17,
  佐渡簡易裁判所: 17,
  高田簡易裁判所: 17,
  直江津簡易裁判所: 17,
  南魚沼簡易裁判所: 17,
  富山簡易裁判所: 17,
  高岡簡易裁判所: 17,
  魚津簡易裁判所: 17,
  礪波簡易裁判所: 17,
  泊簡易裁判所: 17,
  金沢簡易裁判所: 17,
  小松簡易裁判所: 17,
  七尾簡易裁判所: 17,
  輪島簡易裁判所: 17,
  珠洲簡易裁判所: 17,
  石川飯田簡易裁判所: 17,
  福井簡易裁判所: 17,
  大野簡易裁判所: 17,
  '勝山簡易裁判所（福井）': 17,
  武生簡易裁判所: 17,
  敦賀簡易裁判所: 17,
  小浜簡易裁判所: 17,
  甲府簡易裁判所: 17,
  山梨簡易裁判所: 17,
  富士吉田簡易裁判所: 17,
  鰍沢簡易裁判所: 17,
  都留簡易裁判所: 17,
  大月簡易裁判所: 17,
  谷村簡易裁判所: 17,
  長野簡易裁判所: 17,
  屋代簡易裁判所: 17,
  飯田簡易裁判所: 17,
  伊那簡易裁判所: 17,
  諏訪簡易裁判所: 17,
  松本簡易裁判所: 17,
  岡谷簡易裁判所: 17,
  上田簡易裁判所: 17,
  飯山簡易裁判所: 17,
  佐久簡易裁判所: 17,
  木曾福島簡易裁判所: 17,
  大町簡易裁判所: 17,
  郡上簡易裁判所: 17,
  岐阜簡易裁判所: 17,
  大垣簡易裁判所: 17,
  中津川簡易裁判所: 17,
  多治見簡易裁判所: 17,
  御嵩簡易裁判所: 17,
  八幡簡易裁判所: 17,
  高山簡易裁判所: 17,
  関簡易裁判所: 17,
  静岡簡易裁判所: 17,
  島田簡易裁判所: 17,
  清水簡易裁判所: 17,
  二俣簡易裁判所: 17,
  沼津簡易裁判所: 17,
  浜松簡易裁判所: 17,
  掛川簡易裁判所: 17,
  森簡易裁判所: 17,
  三島簡易裁判所: 17,
  熱海簡易裁判所: 17,
  富士簡易裁判所: 17,
  下田簡易裁判所: 17,
  吉原簡易裁判所: 17,
  名古屋簡易裁判所: 17,
  中川簡易裁判所: 17,
  西尾簡易裁判所: 17,
  西枇杷島簡易裁判所: 17,
  豊橋簡易裁判所: 17,
  岡崎簡易裁判所: 17,
  愛知中村簡易裁判所: 17,
  津島簡易裁判所: 17,
  一宮簡易裁判所: 17,
  半田簡易裁判所: 17,
  昭和簡易裁判所: 17,
  春日井簡易裁判所: 17,
  瀬戸簡易裁判所: 17,
  犬山簡易裁判所: 17,
  安城簡易裁判所: 17,
  豊田簡易裁判所: 17,
  新城簡易裁判所: 17,
  伊賀簡易裁判所: 17,
  津簡易裁判所: 17,
  四日市簡易裁判所: 17,
  熊野簡易裁判所: 17,
  上野簡易裁判所: 17,
  鈴鹿簡易裁判所: 17,
  松阪簡易裁判所: 17,
  伊勢簡易裁判所: 17,
  尾鷲簡易裁判所: 17,
  桑名簡易裁判所: 17,
  高島簡易裁判所: 17,
  大津簡易裁判所: 17,
  水口簡易裁判所: 17,
  近江八幡簡易裁判所: 17,
  八日市簡易裁判所: 17,
  今津簡易裁判所: 17,
  彦根簡易裁判所: 17,
  長浜簡易裁判所: 17,
  甲賀簡易裁判所: 17,
  東近江簡易裁判所: 17,
  京丹後簡易裁判所: 17,
  京都簡易裁判所: 17,
  舞鶴簡易裁判所: 17,
  峰山簡易裁判所: 17,
  伏見簡易裁判所: 17,
  右京簡易裁判所: 17,
  向日町簡易裁判所: 17,
  木津簡易裁判所: 17,
  宇治簡易裁判所: 17,
  園部簡易裁判所: 17,
  亀岡簡易裁判所: 17,
  宮津簡易裁判所: 17,
  福知山簡易裁判所: 17,
  堺簡易裁判所: 17,
  岸和田簡易裁判所: 17,
  阿倍野簡易裁判所: 17,
  生野簡易裁判所: 17,
  吹田簡易裁判所: 17,
  柏原簡易裁判所: 17,
  西淀川簡易裁判所: 17,
  茨木簡易裁判所: 17,
  枚方簡易裁判所: 17,
  富田林簡易裁判所: 17,
  羽曳野簡易裁判所: 17,
  布施簡易裁判所: 17,
  豊中簡易裁判所: 17,
  神戸簡易裁判所: 17,
  尼崎簡易裁判所: 17,
  姫路簡易裁判所: 17,
  西宮簡易裁判所: 17,
  洲本簡易裁判所: 17,
  灘簡易裁判所: 17,
  浜坂簡易裁判所: 17,
  豊岡簡易裁判所: 17,
  明石簡易裁判所: 17,
  新宮簡易裁判所: 17,
  篠山簡易裁判所: 17,
  加古川簡易裁判所: 17,
  龍野簡易裁判所: 17,
  山崎簡易裁判所: 17,
  八鹿簡易裁判所: 17,
  奈良簡易裁判所: 17,
  葛城簡易裁判所: 17,
  伊丹簡易裁判所: 17,
  社簡易裁判所: 17,
  宇陀簡易裁判所: 17,
  五條簡易裁判所: 17,
  吉野簡易裁判所: 17,
  和歌山簡易裁判所: 17,
  橋本簡易裁判所: 17,
  御坊簡易裁判所: 17,
  妙寺簡易裁判所: 17,
  海南簡易裁判所: 17,
  串本簡易裁判所: 17,
  湯浅簡易裁判所: 17,
  田辺簡易裁判所: 17,
  鳥取簡易裁判所: 17,
  米子簡易裁判所: 17,
  倉吉簡易裁判所: 17,
  木次簡易裁判所: 17,
  出雲簡易裁判所: 17,
  川本簡易裁判所: 17,
  益田簡易裁判所: 17,
  西郷簡易裁判所: 17,
  八橋簡易裁判所: 17,
  松江簡易裁判所: 17,
  岩村田簡易裁判所: 17,
  赤湯簡易裁判所島根: 17,
  今市簡易裁判所: 17,
  島根大田簡易裁判所: 17,
  浜田簡易裁判所: 17,
  雲南簡易裁判所: 17,
  岡山簡易裁判所: 17,
  玉島簡易裁判所: 17,
  備前簡易裁判所: 17,
  津山簡易裁判所: 17,
  玉野簡易裁判所: 17,
  倉敷簡易裁判所: 17,
  新見簡易裁判所: 17,
  高梁簡易裁判所: 17,
  児島簡易裁判所: 17,
  笠岡簡易裁判所: 17,
  '勝山簡易裁判所（岡山）': 17,
  美作簡易裁判所: 17,
  牛窓簡易裁判所: 17,
  林野簡易裁判所: 17,
  広島簡易裁判所: 17,
  竹原簡易裁判所: 17,
  尾道簡易裁判所: 17,
  安芸吉田簡易裁判所: 17,
  東広島簡易裁判所: 17,
  福山簡易裁判所: 17,
  呉簡易裁判所: 17,
  大竹簡易裁判所: 17,
  可部簡易裁判所: 17,
  三次簡易裁判所: 17,
  庄原簡易裁判所: 17,
  府中簡易裁判所: 17,
  防府簡易裁判所: 17,
  加計簡易裁判所: 17,
  周南簡易裁判所: 17,
  山口簡易裁判所: 17,
  下関簡易裁判所: 17,
  岩国簡易裁判所: 17,
  宇部簡易裁判所: 17,
  船木簡易裁判所: 17,
  徳山簡易裁判所: 17,
  萩簡易裁判所: 17,
  長門簡易裁判所: 17,
  柳井簡易裁判所: 17,
  徳島簡易裁判所: 17,
  脇町簡易裁判所: 17,
  阿南簡易裁判所: 17,
  徳島池田簡易裁判所: 17,
  鳴門簡易裁判所: 17,
  川島簡易裁判所徳島: 17,
  牟岐簡易裁判所: 17,
  美馬簡易裁判所: 17,
  吉野川簡易裁判所: 17,
  高松簡易裁判所: 17,
  土庄簡易裁判所: 17,
  丸亀簡易裁判所: 17,
  善通寺簡易裁判所: 17,
  観音寺簡易裁判所: 17,
  平井簡易裁判所: 17,
  松山簡易裁判所: 17,
  宇和島簡易裁判所: 17,
  愛媛三島簡易裁判所: 17,
  野村簡易裁判所: 17,
  八幡浜簡易裁判所: 17,
  大洲簡易裁判所: 17,
  西条簡易裁判所: 17,
  新居浜簡易裁判所: 17,
  今治簡易裁判所: 17,
  伊予三島簡易裁判所: 17,
  城辺簡易裁判所: 17,
  四国中央簡易裁判所: 17,
  愛南簡易裁判所: 17,
  高知簡易裁判所: 17,
  赤岡簡易裁判所: 17,
  本山簡易裁判所: 17,
  安芸簡易裁判所: 17,
  須崎簡易裁判所: 17,
  中村簡易裁判所: 17,
  福岡簡易裁判所: 17,
  行橋簡易裁判所: 17,
  小倉簡易裁判所: 17,
  吉井簡易裁判所: 17,
  久留米簡易裁判所: 17,
  田川簡易裁判所: 17,
  飯塚簡易裁判所: 17,
  直方簡易裁判所: 17,
  柳川簡易裁判所: 17,
  門司簡易裁判所: 17,
  宗像簡易裁判所: 17,
  甘木簡易裁判所: 17,
  八女簡易裁判所: 17,
  大牟田簡易裁判所: 17,
  折尾簡易裁判所: 17,
  うきは簡易裁判所: 17,
  佐賀簡易裁判所: 17,
  唐津簡易裁判所: 17,
  小城簡易裁判所: 17,
  鳥栖簡易裁判所: 17,
  武雄簡易裁判所: 17,
  鹿島簡易裁判所: 17,
  伊万里簡易裁判所: 17,
  長崎簡易裁判所: 17,
  天草簡易裁判所: 17,
  佐世保簡易裁判所: 17,
  平戸簡易裁判所: 17,
  長崎小浜簡易裁判所: 17,
  大村簡易裁判所: 17,
  諫早簡易裁判所: 17,
  島原簡易裁判所: 17,
  福江簡易裁判所: 17,
  有川簡易裁判所: 17,
  厳原簡易裁判所: 17,
  上県簡易裁判所: 17,
  壱岐簡易裁判所: 17,
  大瀬戸簡易裁判所: 17,
  五島簡易裁判所: 17,
  新上五島簡易裁判所: 17,
  熊本簡易裁判所: 17,
  山鹿簡易裁判所: 17,
  水俣簡易裁判所: 17,
  宮地簡易裁判所: 17,
  人吉簡易裁判所: 17,
  玉名簡易裁判所: 17,
  三角簡易裁判所: 17,
  御船簡易裁判所: 17,
  高森簡易裁判所: 17,
  荒尾簡易裁判所: 17,
  八代簡易裁判所: 17,
  牛深簡易裁判所: 17,
  阿蘇簡易裁判所: 17,
  宇城簡易裁判所: 17,
  大分簡易裁判所: 17,
  中津簡易裁判所: 17,
  宇佐簡易裁判所: 17,
  国東簡易裁判所: 17,
  杵築簡易裁判所: 17,
  別府簡易裁判所: 17,
  竹田簡易裁判所: 17,
  臼杵簡易裁判所: 17,
  佐伯簡易裁判所: 17,
  豊後高田簡易裁判所: 17,
  日田簡易裁判所: 17,
  宮崎簡易裁判所: 17,
  小林簡易裁判所: 17,
  延岡簡易裁判所: 17,
  都城簡易裁判所: 17,
  西都簡易裁判所: 17,
  日南簡易裁判所: 17,
  日向簡易裁判所: 17,
  高千穂簡易裁判所: 17,
  鹿児島簡易裁判所: 17,
  鹿屋簡易裁判所: 17,
  名瀬簡易裁判所: 17,
  大隅簡易裁判所: 17,
  出水簡易裁判所: 17,
  伊集院簡易裁判所: 17,
  種子島簡易裁判所: 17,
  屋久島簡易裁判所: 17,
  知覧簡易裁判所: 17,
  加世田簡易裁判所: 17,
  指宿簡易裁判所: 17,
  加治木簡易裁判所: 17,
  大口簡易裁判所: 17,
  川内簡易裁判所: 17,
  甑島簡易裁判所: 17,
  徳之島簡易裁判所: 17,
  大根占簡易裁判所: 17,
  那覇簡易裁判所: 17,
  名護簡易裁判所: 17,
  沖縄簡易裁判所: 17,
  平良簡易裁判所: 17,
  石垣簡易裁判所: 17,
  川島簡易裁判所: 17,
  在遼陽大日本総領事館: 18,
  在奉天大日本総領事館: 18,
  在済南大日本総領事館: 18,
  在哈爾賓大日本総領事館: 18,
  京城覆審法院: 18,
  大邱覆審法院: 18,
  平壌理事庁: 18,
  朝鮮高等法院: 19,
  台湾高等法院: 19,
  釜山地方院全支部: 19,
  釜山地方院: 19,
  釜山地方院馬山支部: 19,
  咸興地方院: 19,
});
