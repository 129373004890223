var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"page-container",attrs:{"fluid":""}},[(_vm.initialized)?[_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showInputForm),expression:"!showInputForm"}],staticClass:"fab",attrs:{"color":"primary","fab":""},on:{"click":function($event){_vm.showInputForm = !_vm.showInputForm}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_vm._v(" "),_c('v-slide-y-reverse-transition',[_c('labs-wandh-input-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInputForm),expression:"showInputForm"}],attrs:{"state":_vm.state,"abort-controller":_vm.abortController,"show-close-button":true},on:{"close":function($event){_vm.showInputForm = false}}})],1),_vm._v(" "),_c('v-slide-y-transition',[(_vm.sentQuery)?_c('v-row',{ref:"resultWrapper",staticClass:"result-wrapper"},[_c('holmes',{ref:"holmes",attrs:{"sent-query":_vm.sentQuery,"state":_vm.state,"answer":_vm.answer,"answer-timered":_vm.answerTimered,"error":_vm.error,"selected-reference":_vm.selectedReference,"handle-select-reference":_vm.handleSelectReference,"references":_vm.references,"laws":_vm.laws}}),_vm._v(" "),_c('watson',_vm._b({ref:"watson"},'watson',{
            sentQuery: _vm.sentQuery,
            state: _vm.state,
            selectedReference: _vm.selectedReference,
            handleSelectReference: _vm.handleSelectReference,
            handleUnfocusReference: _vm.handleUnfocusReference,
            references: _vm.references,
            answer: _vm.answer,
            watsonTab: _vm.watsonTab,
            watsonTabs: _vm.watsonTabs,
            changeWatsonTab: _vm.changeWatsonTab,
            laws: _vm.laws,
          },false))],1):_vm._e()],1)]:_c('v-progress-circular',{staticClass:"loading",attrs:{"indeterminate":"","size":"64"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }