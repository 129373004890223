import { useAuth } from '@/plugins/auth';
import { useTelemetry } from '@/plugins/telemetry';
import { useStore } from '@/store/useStore';
import { ImpressionDetector } from '@/utils/impressionUtils';
export default defineComponent({
  props: {
    tabType: {
      type: String,
      required: true
    },
    tabName: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      default: 'Watson'
    },
    isFound: {
      type: Boolean,
      required: true
    },
    isIncluded: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup(props) {
    var telemetry = useTelemetry();
    var route = useRoute();
    var impressionDetector = ref();
    var store = useStore();
    var auth = useAuth();
    var showD1TrialNav = computed({
      get: function get() {
        return !store.state.persistent.hasClosedWandhD1Trial;
      },
      set: function set(val) {
        return store.commit('setHasClosedWandhD1Trial', !val);
      }
    });
    var tab = ref(null);
    onMounted(function () {
      if (!tab.value) {
        return;
      }
      impressionDetector.value = new ImpressionDetector(telemetry, route, tab.value,
      // 10% 以上の領域が 3000 ミリ秒以上表示されたらインプレッションイベントを送る
      0.1, 3000, "watson-tab-".concat(props.tabType));
    });
    onBeforeUnmount(function () {
      var _impressionDetector$v;
      (_impressionDetector$v = impressionDetector.value) === null || _impressionDetector$v === void 0 ? void 0 : _impressionDetector$v.cleanup();
    });
    var sendD1TrialClickTelemetry = function sendD1TrialClickTelemetry() {
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__watson-tab__visit-d1-trial'
      }, route);
    };
    var sendD1TrialNavDismissalTelemetry = function sendD1TrialNavDismissalTelemetry() {
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__watson-tab__dismiss-d1-trial'
      }, route);
    };
    return {
      tab: tab,
      showD1TrialNav: showD1TrialNav,
      sendD1TrialClickTelemetry: sendD1TrialClickTelemetry,
      sendD1TrialNavDismissalTelemetry: sendD1TrialNavDismissalTelemetry,
      auth: auth
    };
  }
});